
// const REQUEST_URL = 'https://api.jamboxsys.com/test';     // 测试地址
// const REQUEST_URL = 'http://192.168.3.182:8078/test/'; // 本地地址


// const REQUEST_URL = 'https://test.cpopsz.com/test';          // 测试
const REQUEST_URL = 'https://api.jamboxsys.com/jambox';   // 线上地址


/**
 * @description: 你好呀
 */


export { 
  REQUEST_URL
}