<template>
  <!-- 招聘列表 -->
  <el-card v-loading="loadingTab">
    <!-- 表格数据操作  -->
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="模糊搜索" v-model="dataFrom.remarks"> </el-input>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="postTypevalue"
          placeholder="岗位筛选"
          clearable
          @change="avtivityType()"
        >
          <el-option
            v-for="item in postList"
            :label="item"
            :value="item"
            :key="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-cascader
          v-model="adCodevalue"
          :options="areaData"
          :props="{ expandTrigger: 'hover' }"
          clearable
          placeholder="请选择地区"
          filterable
          @change="areaChange"
        ></el-cascader>
      </el-col>
      <el-col :span="1.5">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="selListCilik()"
        >
          <i class="el-icon-search"></i>
          筛选
        </el-button>
      </el-col>
    </el-row>

    <!-- 表格 -->
    <el-table
      :data="tabList"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column prop="brandName" label="机构名称"> </el-table-column>
      <el-table-column prop="address" label="机构地址" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="post" label="招聘岗位"> </el-table-column>
      <el-table-column prop="natureTitle" label="岗位性质"> </el-table-column>
      <el-table-column
        prop="position"
        label="职位详情"
        show-overflow-tooltip
        width="180"
      >
      </el-table-column>
      <el-table-column label="新资范围">
        <template slot-scope="scope">
          <label>{{ scope.row.salaryMin }}</label>
          ~
          <label>{{ scope.row.salaryMax }}</label>
        </template>
      </el-table-column>
      <el-table-column
        prop="skill"
        label="技能需求"
        show-overflow-tooltip
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="treatment"
        label="福利待遇"
        show-overflow-tooltip
        width="180"
      >
      </el-table-column>
      <el-table-column prop="creationTime" label="发布时间"> </el-table-column>
      <el-table-column prop="newResum" label="投递次数"> </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="dataFrom.pageNum"
      :page-size="dataFrom.pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import { redruitingList } from "@/api/index";
// 导入广东省地区编号文件
import { area } from "@/util/area-data";
export default {
  data() {
    return {
      dataFrom: {
        pageNum: 1,
        pageSize: 8,
        salaryMax: 0,
        salaryMin: 0,
        post: "",
        remarks: "",
        adCode: "",
      },
      tabList: [],
      postList: [], // 岗位列表
      loadingTab: false,
      total: 0, // 表格总数据
      // 地区下拉列表
      adCodevalue: [],
      areaData: [],
      // 岗位筛选
      postTypevalue: "",
      // 岗位列表
      postType: [],
    };
  },
  methods: {
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      this.getTableList();
    },

    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      this.getTableList();
    },
    // 获取数据
    async getTableList() {
      this.loadingTab = true;
      const dataList = await redruitingList(
        "post",
        this.dataFrom,
        "/ass/recruit/recruitPageList"
      );
      if (dataList.status != "success")
        return this.$$message.error("获取数据失败");
        console.log(dataList);
      this.tabList = dataList.data.list;
      this.total = parseInt(dataList.data.total); //获取一共多少条数据
      this.loadingTab = false;
    },
    // 获取岗位列表
    async getPostList() {
      const postList = await redruitingList(
        "post",
        {},
        "/ass/recruit/postList"
      );
      this.postList = postList.data;
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    // 筛选招聘信息事件
    selListCilik() {
      this.getTableList();
    },
    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.dataFrom.adCode = this.adCodevalue[0];
      } else {
        this.dataFrom.adCode = this.adCodevalue[1];
      }
    },
    // 岗位筛选
    avtivityType() {
      this.dataFrom.post = this.postTypevalue;
    },
  },
  created() {
    this.getTableList();
    // 获取岗位列表
    this.getPostList();
    this.areaData = area;
  },
};
</script>

<style>
</style>