<template>
  <!-- 简历列表 -->
  <el-card v-loading="loadingTab">
    <!-- 表格数据操作  -->
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="模糊搜索" v-model="dataFrom.remarks"> </el-input>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="postTypevalue"
          placeholder="岗位筛选"
          clearable
          @change="avtivityType()"
        >
          <el-option
            v-for="item in postList"
            :label="item"
            :value="item"
            :key="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-cascader
          v-model="adCodevalue"
          :options="areaData"
          :props="{ expandTrigger: 'hover' }"
          clearable
          placeholder="请选择地区"
          filterable
          @change="areaChange"
        ></el-cascader>
      </el-col>
      <el-col :span="1.5">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="selListCilik()"
        >
          <i class="el-icon-search"></i>
          筛选
        </el-button>
      </el-col>
      <el-col :span="2">
        <el-checkbox
          style="margin-top: 10px"
          v-model="assTagchecked"
          @change="getTabList()"
          >允许公开</el-checkbox
        >
      </el-col>
    </el-row>

    <el-table
      :data="tabList"
      style="width: 100%"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column  label="头像" width="115">
        <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.avatarUrl"
            fit="cover"
            :previewSrcList="[scope.row.avatarUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="sex" label="性别"> </el-table-column>
      <el-table-column prop="birthday" label="生日"> </el-table-column>
      <el-table-column prop="phone" label="电话"> </el-table-column>
      <el-table-column prop="Email" label="邮箱"> </el-table-column>
      <el-table-column prop="nativePlace" label="籍贯"> </el-table-column>
      <el-table-column  prop="region" label="所在地" width="180px" show-overflow-tooltip > </el-table-column>
      <el-table-column prop="educational" label="学历"> </el-table-column>
      <el-table-column prop="graduation" label="毕业学校"> </el-table-column>
      <el-table-column prop="major" label="专业"> </el-table-column>
      <!-- <el-table-column prop="natureTitle" label="工作性质"> </el-table-column> -->
      <el-table-column prop="danceList" label="擅长舞种"> </el-table-column>
      <el-table-column prop="postList" label="意向岗位"> </el-table-column>
      <el-table-column  label="薪资范围">
        <template slot-scope="scope">
          {{scope.row.salaryMin}} - {{scope.row.salaryMax}}
        </template>
      </el-table-column>
      <el-table-column prop="introduction" label="个人介绍" show-overflow-tooltip > </el-table-column>
      <el-table-column prop="skill" label="个人技能" show-overflow-tooltip > </el-table-column>
      <el-table-column prop="award" label="获奖经历" show-overflow-tooltip > </el-table-column>
      <el-table-column prop="experience" label="工作经历" show-overflow-tooltip > </el-table-column>
      <el-table-column prop="title" label="简历名称"> </el-table-column>
      <el-table-column prop="creationTime" label="上传时间"> </el-table-column>
      <el-table-column prop="postList" label="岗位列表" width="180px" show-overflow-tooltip > </el-table-column>

      <el-table-column label="是否允许公开">
        <template slot-scope="scope">
          <label v-show="scope.row.assTag == 1">公开</label>
          <label v-show="scope.row.assTag == 0">不公开</label>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="120">
        <template v-slot="scope">
          <el-button
            type="primary"
            plain
            icon="el-icon-tickets"
            size="small"
            @click="viewDataDrawer(scope.row)"
            >查看简历</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="dataFrom.pageNum"
      :page-size="dataFrom.pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import { redruitingList } from "@/api/index";
// 导入广东省地区编号文件
import { area } from "@/util/area-data";

import { xlsTable, priview } from "@/util/index";

export default {
  data() {
    return {
      dataFrom: {
        pageNum: 1,
        pageSize: 8,
        assTag: "",
        post: "",
        adCode: "",
        remarks: "",
      },
      tabList: [],
      postList: [], //岗位列表
      loadingTab: false,
      total: 0, // 表格总数据
      // 地区下拉列表
      adCodevalue: [],
      areaData: [],
      // 岗位筛选
      postTypevalue: "",
      // 岗位列表
      postType: [],
      assTagchecked: false,
    };
  },
  methods: {
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      this.getTabList();
    },

    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      this.getTabList();
    },
    // 获取数据
    async getTabList() {
      this.loadingTab = true;
      this.dataFrom.assTag = this.assTagchecked ? "1" : "";
      console.log(this.dataFrom);
      const dataList = await redruitingList(
        "post",
        this.dataFrom,
        "/ass/recruit/resumeAdminList"
      );
      this.tabList = dataList.data.list;
      this.total = parseInt(dataList.data.total);
      this.loadingTab = false;
    },
    // 获取岗位列表
    async getPostList() {
      const postList = await redruitingList(
        "post",
        {},
        "/ass/recruit/postList"
      );
      this.postList = postList.data;
    },

    // 查看简历
    viewDataDrawer(item) {
      // priview(item);
      if(!item.resumeUrl){
        this.$notify({
          title: '提示',
          message: '无文件'
        });
        return
      }
      window.open(item.resumeUrl)

    },

    // 地区选择
    areaChange() {
      var arr = "";
      // 拼接城市名称
      this.areaData.forEach((item) => {
        if (item.value === this.adCodevalue[0]) {
          arr += item.label;
          item.children.forEach((i) => {
            if (i.value === this.adCodevalue[1]) {
              arr += i.label;
            }
          });
        }
      });
      this.dataFrom.adCode = arr;
    },
    // 岗位筛选
    avtivityType() {
      this.dataFrom.post = this.postTypevalue;
    },
    // 筛选按钮事件
    selListCilik() {
      this.getTabList();
    },
  },
  created() {
    // 获取表格数据
    this.getTabList();
    // 获取岗位列表
    this.getPostList();
    this.areaData = area;
  },
};
</script>

<style scoped>
.resumexq {
  width: 400px;
  height: 500px;
  background-color: aqua;
  padding: 20px;
  box-sizing: border-box;
}
</style>