<template>
  <!-- 老会员绑定组件 -->
  <div v-loading="loadingTab">
    <div>
      <!-- 表格数据操作  -->
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input
            placeholder="地区或品牌查询"
            v-model="getTableListFrom.remarks"
          >
          </el-input>
        </el-col>
        <el-col :span="3">
          <div class="block">
            <el-cascader
              v-model="adCodevalue"
              :options="areaData"
              :props="{ expandTrigger: 'hover' }"
              clearable
              placeholder="请选择地区"
              filterable
              @change="areaChange"
            ></el-cascader>
          </div>
        </el-col>
        <el-col :span="3">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="getTableList()"
          >
            <i class="el-icon-search"></i> </el-button
        ></el-col>
        <el-col :span="2">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            icon="el-icon-download"
            @click="derivedForm"
          >
            导出
          </el-button>
        </el-col>
      </el-row>

      <!-- <TbaPag /> -->
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column type="index" label="#" width="40"> </el-table-column>
        <el-table-column prop="brandName" label="机构名称"> </el-table-column>
        <el-table-column prop="chargeName" label="负责人"> </el-table-column>
        <el-table-column prop="chargePhone" label="联系电话"> </el-table-column>
        <el-table-column
          prop="brandAddress"
          label="通讯地址"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="plaqueNo" label="牌匾号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="creationTime"
          label="申请时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="310">
          <template v-slot="scope">
            <el-row :gutter="30">
              <el-col :span="8">
                <el-button
                  icon="el-icon-close"
                  type="danger"
                  plain
                  size="small"
                  @click="registerDialog(scope.row.registerId)"
                  >驳回修改</el-button
                >
              </el-col>
              <el-col :span="8">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  size="small"
                  @click="registerEditAccDrawer(scope.row)"
                  >修改机构</el-button
                >
              </el-col>
              <!-- 通过 -->
              <el-col :span="8">
                <el-button
                  type="success"
                  plain
                  icon="el-icon-check"
                  size="small"
                  @click="applyOkDialog(scope.row)"
                  >通过</el-button
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="getTableListFrom.pageNum"
        :page-sizes="[1, 2, 5, 8]"
        :page-size="getTableListFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 驳回弹出框 -->
    <el-dialog
      title="老会员驳回"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="8"
          ><p style="font-size: 17px; font-weight: bold">驳回原因：</p></el-col
        >
        <el-col :span="12"
          ><el-input v-model="inputbohui" placeholder="请输入内容"></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="registerDialogClick">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改数据的抽屉 -->
    <el-drawer
      title="修改机构信息"
      :visible.sync="EditAccDrawer"
      direction="ltr"
      custom-class="demo-drawer"
      ref="drawer"
      size="25%"
    >
      <div class="demo-drawer__content">
        <hr style="background-color: #bbb" />
        <el-form :model="EditAccForm" style="padding-right: 20px">
          <el-form-item label="输入机构名称:" label-width="110px">
            <el-input
              v-model="EditAccForm.brandName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="输入负责人:" label-width="110px">
            <el-input
              v-model="EditAccForm.chargeName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="输入联系电话:" label-width="110px">
            <el-input
              v-model="EditAccForm.chargePhone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="输入机构地址:" label-width="110px">
            <el-input
              v-model="EditAccForm.brandAddress"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="输入牌匾号:" label-width="110px">
            <el-input
              v-model="EditAccForm.plaqueNo"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>

        <div class="demo-drawer__footer drawer">
          <el-button>返 回</el-button>
          <el-button type="primary" @click="registerEditYes">{{
            loadingdrawer ? "提交中 ..." : "确 定"
          }}</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 通过弹出框 -->
    <el-dialog
      title="认证通过信息"
      :visible.sync="okDialogVisible"
      width="30%"
      center
    >
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="13"
          ><span style="font-size: 17px; font-weight: bold"
            >牌匾有效期：</span
          ></el-col
        >
        <el-col :span="10">
          <el-date-picker
            v-model="applyOk.time"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            style="width:100%"
          >
          </el-date-picker
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="okDialogClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 导入请求方法
import {
  getAccreditedTableLsit,
  registration,
  registerEdit,
  applyOkserve,
  derivedFormPost,
} from "@/api/index";

// 导入生产xls的方法
import { xlsTable } from "@/util/index";
// 导入广东省地区编号文件
import { area } from "@/util/area-data";

export default {
  data() {
    return {
      // 表格数据
      tableData: [],
      loadingTab: false, //表格加载属性
      centerDialogVisible: false, //驳回弹出框
      okDialogVisible: false, //通过弹出框
      inputbohui: "", //绑定驳回文本框
      EditAccDrawer: false, //修改数据弹框
      // 获取表格数据参数
      getTableListFrom: {
        pageNum: 1,
        pageSize: 8,
        status: "",
        associationId: 1,
        remarks: "",
        adCode: "",
      },
      total: 1, // 表格总数据
      // 修改数据表单
      EditAccForm: {
        registerId: 0,
        remarks: "",
        chargePhone: "", //联系电话
        brandName: "", //机构名称
        chargeName: "", //负责人
        brandAddress: "", //地址
        plaqueNo: "", //牌匾号
      },
      // 临时存储用户的id字段
      storeId: "",
      loadingdrawer: false, //提交修改的加载
      // 老用户通过表单
      applyOk: {
        registerId: 0,
        plaqueNo: "",
        time: "",
      },
      // 地区下拉列表
      adCodevalue: "",
      areaData: [],
    };
  },

  props: ["tabTypeIndex"],
  methods: {
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.getTableListFrom.pageSize = newsize;
      // 重新获取数据
      this.getTableList();
    },

    handleCurrentChange(newpage) {
      this.getTableListFrom.pageNum = newpage;
      // 重新获取数据
      this.getTableList();
    },

    // 获取表格数据方法
    async getTableList() {
      this.loadingTab = true;
      // 发送获取表格数据的请求
      // console.log(window.sessionStorage.getItem("token"));
      const TabList = await getAccreditedTableLsit(this.getTableListFrom);
      console.log(TabList);
      this.tableData = TabList.data.list; //将数据保存
      this.total = parseInt(TabList.data.total); //获取一共多少条数据
      this.loadingTab = false;
    },

    // 驳回修改窗口
    registerDialog(id) {
      this.centerDialogVisible = true;
      this.storeId = id;
    },

    // 确认驳回的按钮事件
    async registerDialogClick() {
      console.log(this.storeId);
      this.centerDialogVisible = false;
      let obj = {
        registerId: this.storeId,
        reason: this.inputbohui,
      };
      // 驳回资料请求
      const data = await registration(obj);
      // console.log(data);
      if (data.status === 'success') {
        this.$message.success("驳回成功！");
        this.getTableList();
      } else {
        this.$message.error("驳回失败！");
        console.log(data);
      }
      this.inputbohui = "";
    },



    //修改机构的弹窗
    registerEditAccDrawer(item) {
      console.log(item);
      this.EditAccDrawer = true;
      Object.keys(this.EditAccForm).forEach((key) => {
        this.EditAccForm[key] = item[key];
      });

      // console.log(this.EditAccForm.mechanismId);
    },

    //确认修改按钮事件
    async registerEditYes() {
      // console.log(this.EditAccForm);
      this.loadingdrawer = true;
      const data = await registerEdit(this.EditAccForm);
      console.log(data);
      this.EditAccDrawer = false;
      if (data.status == "success") {
        this.$message.success("修改机构成功！");
        this.getTableList();
      } else {
        this.$message.error("修改机构失败!!");
      }
      this.loadingdrawer = false;
    },

    // 打开通过弹窗
    applyOkDialog(item) {
      // 获取到该条数据的信息
      this.applyOk.registerId = item.registerId;
      this.applyOk.plaqueNo = item.plaqueNo;
      // 打开弹窗
      this.okDialogVisible = true;
    },

    
    // 确认通过请求事件
    async okDialogClick() {
      console.log(this.applyOk);
      const data = await applyOkserve(this.applyOk);
      if (data.status == "success") {
        this.$message.success("已通过");
        this.okDialogVisible = false;
        this.getTableList();
      } else {
        this.$message.error("操作失败");
      }
    },

    //下拉框 地区选择事件
    selectAddersChange() {
      console.log(this.getTableListFrom.adCode);
      this.getTableList();
    },

    //导出表格
    async derivedForm() {
      let obj = {
        type: this.tabTypeIndex,
        remarks: "",
      };
      const data = await derivedFormPost(obj);
      console.log(data.data);
      // xlsTable(data.data, "老会员表格.xls");
      window.location.href = data.data;
    },


    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.getTableListFrom.adCode = this.adCodevalue[0];
      } else {
        this.getTableListFrom.adCode = this.adCodevalue[1];
      }
    },


  },

  created() {
    this.getTableListFrom.status = this.tabTypeIndex;
    //调用获取表格数据方法
    this.getTableList();
    this.areaData = area;
  },
};
</script>

<style>
</style>