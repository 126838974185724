
// 表格导出方法
export function xlsTable(url, filename) {
    let blob = new Blob([url], {
        type: "application/vnd.ms-excel",
    });
    let alink = document.createElement("a"); //创建一个a标签
    document.body.appendChild(alink); //把a标签添加到body中
    alink.href = URL.createObjectURL(blob); //设置a标签的下载链接路径
    alink.download = filename; //下载后文件名
    alink.click(); //点击下载
    document.body.removeChild(alink); //下载完成移除a标签
    window.URL.revokeObjectURL(alink.href); //释放掉blob对象
}

// 数据预览查看方法

export function priview(item) {
    console.log(item);
    let file = item
    // window.open(file.response)
    if (file) {
        const addTypeArray = file.url.split(".");
        const addType = addTypeArray[addTypeArray.length - 1];
        console.log(addType);
        if (addType === "pdf") {
            // let routeData = this.$router.resolve({
            //     path: "/insurancePdf",
            //     query: { url: file.url, showBack: false },
            // });
            window.open(file.url);
            
            // window.open(
            //     "http://view.officeapps.live.com/op/view.aspx?src=" + file.url
            // );
        }
        //".rar, .zip, .doc, .docx, .xls, .txt, .pdf, .jpg,  .png, .jpeg,"
        else if (addType === "doc" || addType === "docx" || addType === "xls") {
            window.open(
                "http://view.officeapps.live.com/op/view.aspx?src=" + file.url
            );
        } else if (addType === "txt") {
            // window.open(file.url);
            const element = document.createElement("a");
            element.setAttribute(
              "href",
              "data:text/plain;charset=utf-8," + encodeURIComponent(file.url)
            );
            element.setAttribute("download", "myCode");
            element.style.display = "none";
            element.click();
        //    let  pwin = window.open(file.url, '_blank');
        //     pwin.document.charset = "UTF-8";
        } else if (["png", "jpg", "jpeg"].includes(addType)) {
            window.open(file.url);
        } else if (addType === "rar" || addType === "zip") {
            this.$message({
                message: "该文件类型暂不支持预览",
                type: "warning",
            });
            return false;
        }
    }
    // ————————————————
    // 版权声明：本文为CSDN博主「是开心的栗子呀」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
    // 原文链接：https://blog.csdn.net/m0_62209297/article/details/125208566
    // if (item.type == "image") {
    //     window.open(item.url || item.payVoucher, "_blank");
    // } else if (item.type == "file") {
    //     console.log(item.url);
    //     window.open(
    //       "http://view.officeapps.live.com/op/view.aspx?src=" + item.url,  
    //       "_target"
    //     );
    // } else {
    //     alert("此类型文件不支持查看【"+item.type+"】");
    // }
}

