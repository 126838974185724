<template>
  <el-card>
    <!-- 顶部选项卡 -->
    <el-tabs v-model="status" v-if="ElTabs">
      <el-tab-pane
        v-for="item in tabsList"
        :key="item.id"
        :label="item.value"
        :name="item.id"
      ></el-tab-pane>
    </el-tabs>
    <ApplicationReview v-if="status === '0'"></ApplicationReview>
    <ApprovedAdopt v-if="status === '1'"></ApprovedAdopt>
  </el-card>
</template>

<script>
import ApplicationReview from "./Table/ApplicationReview.vue";
import ApprovedAdopt from "./Table/ApprovedAdopt.vue";

export default {
  data() {
    return {
      tabsList: [
        { id: "0", value: "支部审核" },
        { id: "1", value: "已通过" },
      ],
      status: "0",
      ElTabs: true,
    };
  },
  components: {
    ApplicationReview,
    ApprovedAdopt,
  },
};
</script>


<style>
</style>