<template>
  <!-- 支部审核 -->
  <div v-loading="loadingTab">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="请输入关键字" v-model="viewActiveFrom.remarks">
        </el-input>
      </el-col>
      <el-col :span="4">
        <div class="block">
          <el-cascader
            v-model="adCodevalue"
            :options="areaData"
            :props="{ expandTrigger: 'hover' }"
            clearable
            placeholder="请选择地区"
            filterable
            @change="areaChange"
          ></el-cascader>
        </div>
      </el-col>
      <el-col :span="1.5">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="getreviewActivities()"
        >
          <i class="el-icon-search"></i> </el-button
      ></el-col>
    </el-row>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column prop="organizations" label="支部名称"> </el-table-column>
      <el-table-column label="支部类型" show-overflow-tooltip>
        <template v-slot="scope">
          <label>{{ scope.row.type == "0" ? "党支部" : "团支部" }}</label>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="负责人"> </el-table-column>
      <el-table-column prop="phone" label="联系方式"> </el-table-column>
      <!-- </el-table-column> -->
      <el-table-column prop="address" label="支部地址" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="memberStr" label="支部成员" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="审核状态" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="批文" show-overflow-tooltip>
        <template v-slot="scope">
          <el-button @click="donwLode(scope.row)" type="primary" size="small"
            >下载</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="营业执照" width="230">
        <template slot-scope="scope">
            <el-image
              style="width: 90px; height: 60px"
              :src="scope.row.licenseUrl"
              fit="cover"
              :previewSrcList="[scope.row.licenseUrl]"
            >
            </el-image>
        </template>
        <!-- <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img
              :src="scope.row.licenseUrl"
              alt=""
              style="width: 300px; height: 300px"
            />
            <img
              slot="reference"
              :src="scope.row.licenseUrl"
              class="active_imgs"
            />
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template v-slot="scope">
          <el-row :gutter="30">
            <el-col :span="12">
              <el-button
                icon="el-icon-close"
                type="danger"
                plain
                size="small"
                @click="registerDialog(scope.row.leagueOrganizationsId)"
                >驳回申请</el-button
              >
            </el-col>
            <!-- 通过 -->
            <el-col :span="12">
              <el-popconfirm
                confirm-button-text="通过"
                cancel-button-text="取消"
                icon-color="red"
                title="确定通过这条申请吗？"
                @confirm="Okapply(scope.row.leagueOrganizationsId)"
              >
                <el-button
                  slot="reference"
                  type="success"
                  plain
                  icon="el-icon-check"
                  size="small"
                  >通过</el-button
                >
              </el-popconfirm>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="viewActiveFrom.pageNum"
      :page-size="viewActiveFrom.pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 驳回弹出框 -->
    <el-dialog
      title="老用户驳回"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="7"
          ><p style="font-size: 17px; font-weight: bold">驳回原因：</p></el-col
        >
        <el-col :span="12"
          ><el-input
            v-model="bohuiFrom.reason"
            placeholder="请输入内容"
          ></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="registerDialogClick()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  LeagueOrganizationsPageList,
  LeagueOrganizationsExamineRef,
  LeagueOrganizationsExamineAbo,
} from "@/api/index";

// 导入广东省地区编号文件
import { area } from "@/util/area-data";
export default {
  data() {
    return {
      tableData: [],
      total: 0, // 表格总数据
      loadingTab: false, //表格加载属性
      viewActiveFrom: {
        pageNum: 1,
        pageSize: 8,
        status: 0,
        remarks: "",
        adCode: "",
      },
      centerDialogVisible: false, //驳回弹出框
      bohuiFrom: {
        leagueOrganizationsId: "",
        reason: "",
      },
      // 地区下拉列表
      adCodevalue: "",
      areaData: [],
    };
  },
  methods: {
    // 获取数据
    async getreviewActivities() {
      const res = await LeagueOrganizationsPageList(this.viewActiveFrom);
      console.log(res);
      this.tableData = res.data.list;
      this.total = parseInt(res.data.total);
      this.loadingTab = false;
    },
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.viewActiveFrom.pageSize = newsize;
      // 重新获取数据
      this.getreviewActivities();
    },

    handleCurrentChange(newpage) {
      this.viewActiveFrom.pageNum = newpage;
      // 重新获取数据
      this.getreviewActivities();
    },
    // 驳回修改窗口
    registerDialog(id) {
      this.centerDialogVisible = true;
      this.bohuiFrom.leagueOrganizationsId = id;
    },
    // 下载
    donwLode(item) {
      window.open(item.approval)
      // let a = document.createElement("a");
      // a.href = item.approval;
      // a.click();
    },

    // 确认驳回的按钮事件
    async registerDialogClick() {
      // 驳回资料请求
      console.log(this.bohuiFrom);
      const data = await LeagueOrganizationsExamineRef(this.bohuiFrom);
      console.log(data);
      if (data.status === "success") {
        this.$message.success("驳回成功！");
        this.getreviewActivities();
      } else {
        this.$message.error("驳回失败！");
        console.log(data);
      }
      this.bohuiFrom.reason = "";
      this.centerDialogVisible = false;
    },

    // 确认通过事件
    async Okapply(id) {
      // 驳回资料请求
      console.log(id);
      const data = await LeagueOrganizationsExamineAbo({
        leagueOrganizationsId: id,
      });
      if (data.status === "success") {
        this.$message.success("通过成功！");
        this.getreviewActivities();
      } else {
        this.$message.error("通过失败！");
        console.log(data);
      }
    },

    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.viewActiveFrom.adCode = this.adCodevalue[0];
      } else {
        this.viewActiveFrom.adCode = this.adCodevalue[1];
      }
    },
  },
  created() {
    this.getreviewActivities();
    this.areaData = area;
  },
};
</script>

<style>
</style>