<template>
  <!-- 活动审核 -->
  <div v-loading="loadingTab">
    <!-- 表格数据操作  -->
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="活动名称查询" v-model="viewActiveFrom.remarks">
        </el-input>
      </el-col>
      <el-col :span="3">
        <div class="block">
          <el-cascader
            v-model="adCodevalue"
            :options="areaData"
            :props="{ expandTrigger: 'hover' }"
            clearable
            placeholder="请选择地区"
            filterable
            @change="areaChange"
          ></el-cascader>
        </div>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="avtivityTypevalue"
          placeholder="活动类型"
          @change="avtivityType()"
          clearable
        >
          <el-option
            v-for="item in activityType"
            :label="item"
            :value="item"
            :key="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="1.5">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="getreviewActivities()"
        >
          <i class="el-icon-search"></i> </el-button
      ></el-col>
    </el-row>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column prop="name" label="机构名称"> </el-table-column>
      <el-table-column prop="title" label="活动名称"> </el-table-column>
      <el-table-column prop="type" label="活动类型"> </el-table-column>
      <!-- </el-table-column> -->
      <el-table-column
        prop="creationTime"
        label="活动时间"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="remarks"
        label="备注"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="活动照片" width="230">
        <template slot-scope="scope">
            <el-image
              style="width: 90px; height: 60px"
              :src="scope.row.picUrl"
              fit="cover"
              :previewSrcList="[scope.row.picUrl]"
            >
            </el-image>
        </template>
        <!-- <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img
              :src="scope.row.picUrl"
              alt=""
              style="width: 300px; height: 300px"
            />
            <img slot="reference" :src="scope.row.picUrl" class="active_imgs" />
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column label="查看内容" width="230">
        <template slot-scope="scope">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            size="small"
            @click="okDialogVisibleClik(scope.row.content)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="creationTime"
        label="上传时间"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="操作" width="260" fixed="right">
        <template v-slot="scope">
          <el-row>
            <el-col :span="8">
              <!-- <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定驳回通过这活动条申请吗？"
                @confirm="trueDownapply(scope.row)"
              > -->
                <el-button
                  type="warning"
                  plain
                  icon="el-icon-close"
                  size="small"
                  @click="registerDialogClick(scope.row)"
                  >驳回</el-button
                >
              <!-- </el-popconfirm> -->
            </el-col>
            <el-col :span="8">
              <el-popconfirm
                confirm-button-text="通过"
                cancel-button-text="取消"
                icon-color="red"
                title="确定通过这条申请吗？"
                @confirm="Okapply(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="success"
                  plain
                  icon="el-icon-check"
                  size="small"
                  >通过</el-button
                >
              </el-popconfirm>
            </el-col>
            <el-col :span="8">
              <el-popconfirm
                confirm-button-text="通过"
                cancel-button-text="取消"
                icon-color="red"
                title="确定通过这条申请吗？"
                @confirm="Okdele(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  plain
                  icon="el-icon-check"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="viewActiveFrom.pageNum"
      :page-size="viewActiveFrom.pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 富文本内容 -->

    <el-drawer
      title="查看活动内容"
      :visible.sync="okDialogVisible"
      direction="ltr"
      custom-class="demo-drawer"
      ref="drawer"
      size="25%"
    >
      <div class="demo-drawer_max">
        <div
          v-html="
            content.replace(/<img/g, '<img style=max-width:100%;height:auto; ')
          "
          style="height: 200px; width: 300px"
        ></div>
      </div>
      <!-- <div class="demo-drawe_buttpm">
          <el-button type="primary" @click="okDialogVisible = false"
            >返 回</el-button
          >
        </div> -->
    </el-drawer>

    <!-- 驳回 -->
    <el-dialog
      title="活动驳回"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="8"
          ><p style="font-size: 17px; font-weight: bold">驳回原因：</p></el-col
        >
        <el-col :span="12"
          ><el-input v-model="inputbohui" placeholder="请输入内容"></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="trueDownapply">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getreviewActivitiesList,
  trueDownapplyRes,
  activityTypeList,
  partyActivityDel
} from "@/api/index";

// 导入广东省地区编号文件
import { area } from "@/util/area-data";

export default {
  data() {
    return {
      tableData: [],
      total: 0, // 表格总数据
      search: "", //搜素内容绑定
      // activeName: "first",
      loadingTab: false, //表格加载属性
      serachValue: "", //搜索框内容绑定
      // 获取数据表单
      viewActiveFrom: {
        pageNum: 1,
        pageSize: 7,
        associationId: 1,
        status: "0",
        adCode: "",
        type: "",
        remarks: "",
      },
      //活动类型
      activityType: [],
      // 活动类型下拉列表选择的值
      avtivityTypevalue: "",
      // 地区下拉列表
      adCodevalue: "",
      areaData: [],

      // 富文本框
      okDialogVisible: false,
      content: "",
      // 驳回
      inputbohui:"",
      centerDialogVisible:false,
      partyActivityId:'',
    };
  },

  methods: {
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.viewActiveFrom.pageSize = newsize;
      // 重新获取数据
      this.getreviewActivities();
    },

    handleCurrentChange(newpage) {
      this.viewActiveFrom.pageNum = newpage;
      // 重新获取数据
      this.getreviewActivities();
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    // 获取数据方法
    async getreviewActivities() {
      this.loadingTab = true;
      console.log(this.viewActiveFrom);
      const dataLsit = await getreviewActivitiesList(this.viewActiveFrom);
      console.log(dataLsit);
      this.tableData = dataLsit.data.list;
      this.total = parseInt(dataLsit.data.total); //获取一共多少条数据
      this.loadingTab = false;
    },

    // 获取活动列表
    async activityTypeList() {
      const data = await activityTypeList({ associationId: 1 });
      this.activityType = data.data;
      // console.log(this.activityType);
    },

    // 驳回活动申请
    async trueDownapply() {
      // console.log(item.partyActivityId);
      let obj = {
        partyActivityId: this.partyActivityId,
        reason:this.inputbohui
      };
      console.log(obj);
      const data = await trueDownapplyRes(
        "/ass/partyActivity/partyActivityExamineRef",
        obj
      );
      if (data.status == "success") {
        this.$message.success("驳回成功！！");
        // console.log(data);
        this.getreviewActivities();
      } else {
        this.$message.error("驳回失败！！");
      }
      this.centerDialogVisible = false
      this.inputbohui = ""
    },

    registerDialogClick(item){
      this.partyActivityId = item.partyActivityId
      this.centerDialogVisible = true
    },
    // 活动内容查看
    okDialogVisibleClik(content) {
      console.log(content);
      this.content = content;
      this.okDialogVisible = true;
    },

    // 活动申请通过
    async Okapply(item) {
      console.log(item.partyActivityId);
      let obj = {
        partyActivityId: item.partyActivityId,
      };
      const data = await trueDownapplyRes(
        "/ass/partyActivity/partyActivityExamineAbo",
        obj
      );
      if (data.status == "success") {
        this.$message.success("申请通过成功！！");
        // console.log(data);
        this.getreviewActivities();
      } else {
        this.$message.error("申请通过成功！！");
      }
    },

    // 活动删除
   async Okdele(item){
    console.log(item);
    // return
    const data = await partyActivityDel({partyActivityId:item.partyActivityId})
    if (data.status == "success") {
        this.$message.success("删除活动成功！！");
        // console.log(data);
        this.getreviewActivities();
      } else {
        this.$message.error(data.msg);
      }
    },

    // 活动类型
    avtivityType() {
      console.log(this.avtivityTypevalue);
      this.viewActiveFrom.type = this.avtivityTypevalue;
      this.getreviewActivities();
      // this.viewActiveFrom.type = "";
    },

    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.viewActiveFrom.adCode = this.adCodevalue[0];
      } else {
        this.viewActiveFrom.adCode = this.adCodevalue[1];
      }
    },
  },

  created() {
    // 获取列表数据
    this.getreviewActivities();
    // 获取活动列表
    this.activityTypeList();

    const tokenstr = window.sessionStorage.getItem("CHUCtoken");
    // 如果不存在这个令牌的话就强制跳转到登录页面
    if (!tokenstr) return this.$router.push("/Login");
    // console.log(area);
    this.areaData = area;
  },
};
</script>

<style>
.active_imgs {
  width: 50px;
  height: 50px;
  margin-right: 8px;
}
.demo-drawer_max {
  width: 90%;
  height: 90vh;
  overflow: auto;
  border: 2rpx solid #ccc;
  margin: auto;
}
</style>