<template>
  <el-card>
    <div class="welce_max">
      <img
        src="https://6265-beibeike-qy-b33k4-1302318474.tcb.qcloud.la/be54161269319dcbbc61268e2d0f1395.png?sign=a85abba967e2153e0811e712e2ee719e&t=1659059574"
        alt=""
      />
    </div>
    <div class="data_max">
      <div
        class="data_max-item"
        style="background-color: #a16e8a"
        @click="Gopage('/views/registration/index')"
      >
        <p>认证待处理</p>
        <div>{{ mechanism }}</div>
      </div>
      <div
        class="data_max-item"
        style="background-color: #dec5ac"
        @click="Gopage('/views/LeagueOrganizations/examine')"
      >
        <p>支部申请待处理</p>
        <div>{{ leagueOrganizations }}</div>
      </div>
      <div
        class="data_max-item"
        style="background-color: #35838d"
        @click="Gopage('/views/activities/index')"
      >
        <p>活动发布待处理</p>
        <div>{{ partyActivity }}</div>
      </div>
      <div
        class="data_max-item"
        style="background-color: #ea5e51"
        @click="Gopage('/views/activities/commentList')"
      >
        <p>评论待审核</p>
        <div>{{ comment }}</div>
      </div>
      <div
        class="data_max-item"
        style="background-color: #f9bcbd"
        @click="Gopage('/views/registration/index')"
      >
        <p>近三天新注册校区</p>
        <div>{{ register }}</div>
      </div>
      <div
        class="data_max-item"
        style="background-color: #a4c196"
        @click="Gopage('/views/recruit/recruitList')"
      >
        <p>近三天发布招聘</p>
        <div>{{ recruit }}</div>
      </div>
      <div
        class="data_max-item"
        style="background-color: #00997f"
        @click="Gopage('/views/recruit/resumeList')"
      >
        <p>近三天发布简历</p>
        <div>{{ resume }}</div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { pageHome } from "@/api/index";
export default {
  data() {
    return {
      comment: "",
      leagueOrganizations: "",
      mechanism: "",
      partyActivity: "",
      recruit: "",
      register: "",
      resume: "",
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    async getdata() {
      let data = await pageHome({});
      console.log(data);
      this.comment = data.data.comment;
      this.leagueOrganizations = data.data.leagueOrganizations;
      this.mechanism = data.data.mechanism;
      this.partyActivity = data.data.partyActivity;
      this.recruit = data.data.recruit;
      this.register = data.data.register;
      this.resume = data.data.resume;     
    },
    Gopage(page) {
      this.$router.push(page);

      sessionStorage.setItem("activepath", page + "");
      // this.activepath = activepath;
      console.log(sessionStorage.getItem("activepath"));
      
      // console.log();
      window.location.reload()
    },
  },
  components: {},
};
</script>

<style scoped>
.welce_max {
  width: 100%;
  text-align: center;
}
.welce_max img {
  width: 60vh;
}
.data_max {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
}
.data_max-item {
  width: 18vh;
  height: 18vh;
  margin: 10px;
  background-color: aquamarine;
  border-radius: 10px;
  color: #fff;
}
.data_max-item p {
  /* text-align: ;; */
  padding-left: 8px;
}
.data_max-item div {
  font-size: 50px;
  text-align: center;
  margin-top: 15px;
}
</style>