<template>
  <!-- 认证机构组件 -->
  <div v-loading="loadingTab">
    <div>
      <!-- 表格数据操作  -->
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input
            placeholder="地区或品牌查询"
            v-model="getTableListFrom.remarks"
          >
          </el-input>
        </el-col>
        <el-col :span="3">
          <div class="block">
            <el-cascader
              v-model="adCodevalue"
              :options="areaData"
              :props="{ expandTrigger: 'hover' }"
              clearable
              placeholder="请选择地区"
              filterable
              @change="areaChange"
            ></el-cascader>
          </div>
        </el-col>
        <el-col :span="3">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="getTableList()"
          >
            <i class="el-icon-search"></i> </el-button
        ></el-col>
        <el-col :span="2">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            icon="el-icon-download"
            @click="derivedForm"
            >导出</el-button
          >
        </el-col>
      </el-row>

      <!-- <TbaPag /> -->
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column type="index" label="#" width="40"> </el-table-column>
        <el-table-column prop="brandName" label="品牌名称"> </el-table-column>
        <el-table-column prop="chargeName" label="负责人"> </el-table-column>
        <el-table-column prop="chargePhone" label="联系电话"> </el-table-column>
        <el-table-column prop="brandAddress" label="通讯地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="plaqueNo" label="牌匾号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="creationTime"
          label="申请时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="endTime" label="到期时间" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="reason" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="330">
          <template v-slot="scope">
            <el-row :gutter="0">
              <el-col :span="8">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-notebook-1"
                  size="small"
                  @click="viewDataDrawer(scope.row)"
                  >查看资料</el-button
                >
              </el-col>
              <el-col :span="8">
                <el-button
                  type="warning"
                  plain
                  icon="el-icon-notebook-2"
                  size="small"
                  @click="paymentRecordsDialog(scope.row)"
                  >开票信息</el-button
                >
              </el-col>
              <el-col :span="8">
                <el-button
                  type="success"
                  plain
                  icon="el-icon-tickets"
                  size="small"
                  @click="QuestionnaireDetails(scope.row)"
                  >问卷详情</el-button
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="getTableListFrom.pageNum"
        :page-sizes="[1, 2, 5, 8]"
        :page-size="getTableListFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 查看资料对对话框 -->
    <el-dialog
      title="认证通过信息"
      :visible.sync="viewDataDialogVisible"
      width="30%"
      center
    >
      <div>
        <p
          v-show="
            seleleTable == undefined ||
            seleleTable == null ||
            seleleTable.length <= 0
          "
          class="nodata"
        >
          无数据
        </p>
        <p
          class="viewData"
          v-for="item in seleleTable"
          :key="item.associationFileId"
        >
          <label>{{ item.fileName }}:</label>
          <label>
            <el-button @click="viewDataWord(item)" type="primary" size="small">查看</el-button>
            <!-- <a :href="item."></a> -->
          </label>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewDataDialogVisible = false" >确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看缴费记录对话框 -->
    <el-dialog
      title="查看缴费记录"
      :visible.sync="paymentRecordVisible"
      width="40%"
      center
    >
      <div>
        <el-table :data="seleleTable" stripe style="width: 100%" border>
          <el-table-column type="index" label="#" width="80"> </el-table-column>
          <el-table-column prop="plaqueNumber" label="牌匾数量">
          </el-table-column>
          <el-table-column prop="price" label="支付金额"> </el-table-column>
          <el-table-column label="支付凭证" width="180">
            <template v-slot="scope" >
              <el-image
                  style="width: 90px; height: 60px"
                  :src="scope.row.payVoucher"
                  fit="cover" :previewSrcList="[scope.row.payVoucher]">
                </el-image>
              
            </template>
          </el-table-column>
          <el-table-column prop="account" label="银行账户"></el-table-column>
          <el-table-column prop="bankDeposit" label="开户行"></el-table-column>
          <el-table-column prop="socialCode" label="纳税号"></el-table-column>
          <el-table-column
            prop="companyName"
            label="公司名称"
          ></el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="paymentRecordVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-drawer
      title="问卷详情"
      :visible.sync="DetailsDrawer"
      direction="ltr"
      custom-class="demo-drawer"
      ref="drawer"
      size="25%"
    >
    <div class="subject">
      <h4>1、您机构所在的地区</h4>
      <p>答：{{Questionnaire[0]}}</p>
    </div>
    <div class="subject">
      <h4>2、您的会员单位编号</h4>
      <p>答：{{Questionnaire[1]}}</p>
    </div>
    <div class="subject">
      <h4>3、您是从什么渠道了解到CHUC注册会员单位注册的（多选）</h4>
      <p>答：{{Questionnaire[2]}}</p>
    </div>
    <div class="subject">
      <h4>4、贵机构准备在什么时期举办考级？</h4>
      <p>答：{{Questionnaire[3]}}</p>
    </div>
    <div class="subject">
      <h4>5、您在申请注册单位或咨询考级操作的过程中，是否得到及时回复并解决？</h4>
      <p>答：{{Questionnaire[4]}}</p>
    </div>
    <div class="subject">
      <h4>6、您对大众考级（申请流程及费用等）了解吗</h4>
      <p>答：{{Questionnaire[5]}}</p>
    </div>
    <div class="subject">
      <h4>7、贵机构的课程设置是否有与大众考级结合？</h4>
      <p>答：{{Questionnaire[6]}}</p>
    </div>
    <div class="subject">
      <h4>8、贵机构是否有少儿街舞课程？</h4>
      <p>答：{{Questionnaire[7]}}</p>
    </div>
    <div class="subject">
      <h4>9、贵机构的少儿学生参与大众考级的活跃度？</h4>
      <p>答：{{Questionnaire[8]}}</p>
    </div>
    <div class="subject">
      <h4>10、无人报名的原因：</h4>
      <p>答：{{Questionnaire[9]}}</p>
    </div>
    <div class="subject">
      <h4>11、贵机构正在教授的舞种或内容有？（可多选）</h4>
      <p>答：{{Questionnaire[10]}}</p>
    </div>
    <div class="subject">
      <h4>12、您希望CHUC广东街舞联盟更加完善哪方面的工作？</h4>
      <p>答：{{Questionnaire[11]}}</p>
    </div>
    <div class="subject">
      <h4>13、对于付费类型的福利课程，你是否愿意参与？</h4>
      <p>答：{{Questionnaire[12]}}</p>
    </div>
    <div class="subject">
      <h4>14、关于福利课程的学习时长，您希望是？</h4>
      <p>答：{{Questionnaire[13]}}</p>
    </div>
    <div class="subject">
      <h4>15、您希望CHUC广东联络办更加完善哪方面的工作？</h4>
      <p>答：{{Questionnaire[14]}}</p>
    </div>

    </el-drawer>
  </div>
</template>

<script>
// 导入请求方法
import {
  getAccreditedTableLsit,
  viewDataserve,
  paymentRecords,
  derivedFormPost,
  registerQuestionnairec
} from "@/api/index";

// 导入生产xls的方法
import { xlsTable, priview } from "@/util/index";

// 导入广东省地区编号文件
import { area } from "@/util/area-data";

export default {
  data() {
    return {
      // 表格数据
      tableData: [],
      loadingTab: false, //表格加载属性
      viewDataDialogVisible: false, //查看数据弹框
      paymentRecordVisible: false, //查看缴费记录弹框
      // 获取表格数据参数
      getTableListFrom: {
        pageNum: 1,
        pageSize: 8,
        status: "",
        associationId: 1,
        remarks: "",
        adCode: "",
      },
      total: 0, // 表格总数据
      // 临时存储用户的id字段
      storeId: "",
      seleleTable: [], //查看数据存储的数组
            // 地区下拉列表
      adCodevalue: "",
      areaData: [],
      DetailsDrawer:false,
      Questionnaire:[]
    };
  },
  props: ["tabTypeIndex"],
  methods: {
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.getTableListFrom.pageSize = newsize;
      // 重新获取数据
      this.getTableList();
    },

    handleCurrentChange(newpage) {
      this.getTableListFrom.pageNum = newpage;
      // 重新获取数据
      this.getTableList();
    },

    // 获取表格数据方法
    async getTableList() {
      this.loadingTab = true;
      // 发送获取表格数据的请求
      const TabList = await getAccreditedTableLsit(this.getTableListFrom);
      console.log(TabList);
      this.tableData = TabList.data.list; //将数据保存
      this.total = parseInt(TabList.data.total) ; //获取一共多少条数据
      this.loadingTab = false;
      setTimeout(() => {
        this.loadingTab = false;
      }, 1000);
    },

    // 查看资料弹框
    async viewDataDrawer(item) {
      let obj = {
        registerId: item.registerId,
      };
      const data = await viewDataserve(obj);
      this.seleleTable = data.data;
      this.viewDataDialogVisible = true;
    },

    //预览数据
    viewDataWord(item) {
      window.open(item.url)
      // priview(item);
    },

    // 查看缴费记录弹框
    async paymentRecordsDialog(item) {
      this.paymentRecordVisible = true;
      let obj = {
        pageNum: 1,
        pageSize: 7,
        registerId: item.registerId,
        associationId: 1,
      };
      const data = await paymentRecords(obj);
      this.seleleTable = data.data.list;
    },

    //导出表格
    async derivedForm() {
      let obj = {
        type: this.tabTypeIndex,
        remarks: "",
      };
      const data = await derivedFormPost(obj);
      window.location.href = data.data
      // xlsTable(data.data, "认证机构.xls");
    },

        // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.getTableListFrom.adCode = this.adCodevalue[0];
      } else {
        this.getTableListFrom.adCode = this.adCodevalue[1];
      }
    },
    // 问卷详情
    async QuestionnaireDetails(from){
      console.log(from);
        let res = await registerQuestionnairec({brandId:from.brandId})
        console.log(res);
        this.Questionnaire = res.data,
        this.DetailsDrawer = true
    }
  },

  mounted() {
    this.getTableListFrom.status = this.tabTypeIndex;
    //调用获取表格数据方法
    this.getTableList();

    this.areaData = area;
  },
};
</script>

<style scoped>
.viewData {
  display: flex;
  justify-content: space-between;
}
.nodata {
  text-align: center;
  font-size: 20px;
}
.subject{
  width: 90%;
  margin: auto;
}
.subject p{
  color: rgba(12, 70, 20, 0.527);
  font-weight: bold;
}
</style>