// 当前这个模块：API进行统一的管理
import requests from "./request";

// 登录请求方法
export const LoginApi = (query1) => requests({
    url: '/ass/user/testLogin',
    method: "post",
    data: query1
});


// 登录获取菜单列表请求
export const getMenuList = () => requests({
    url: "/ass/user/loginJurisdiction",
    method: "post",
    data: {}
})


// 首页数据
export const pageHome = (obj) => requests({
    url: "/ass/association/pageHome",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})


// 获取机构列表页面的表格数据
export const getAccreditedTableLsit = (obj) => requests({
    url: "/ass/register/registerPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

//机构认证-__老会员绑定驳回请求
export const registration = (obj) => requests({
    url: "/ass/register/registerBindRef",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }

})
//机构认证-__老会员修改机构请求
export const registerEdit = (obj) => requests({
    url: "/ass/register/registerUpd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})


//机构认证-__老会员通过请求
export const applyOkserve = (obj) => requests({
    url: "/ass/register/registerAbo",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

//机构认证-__新会员绑定驳回请求
export const newregistration = (obj) => requests({
    url: "/ass/register/registerExamineRsRef",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 机构认证__新会员查看数据
export const viewDataserve = (obj) => requests({
    url: "/ass/register/registerResourceList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

//机构认证__缴费待确认驳回
export const registerExaminePaRef = (obj) => requests({
    url: '/ass/register/registerExaminePaRef',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

//机构认证__查看缴费记录
export const paymentRecords = (obj) => requests({
    url: '/ass/register/registerPayPageList',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

//机构认证__通过（转缴费）
export const paymentByTrasfer = (obj) => requests({
    url: '/ass/register/registerExamineRsAbo',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

//机构认证__通过（转认证） 4
export const registerExaminePaAbo = (obj) => requests({
    url: '/ass/register/registerExaminePaAbo',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
//机构认证__通过（申请领牌匾号）5
export const receivePlaqueByapplication = (obj) => requests({
    url: '/ass/register/registerAbo',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

export const derivedFormPost = obj => requests({
    url: '/ass/register/registerDataExc',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
// 问卷结果
export const registerQuestionnairec = obj => requests({
    url: '/ass/register/registerQuestionnaire',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// ___________________________________________________________________________________
// 党团支部
// 列表获取
export const LeagueOrganizationsPageList = obj => requests({
    url: '/ass/leagueOrganizations/LeagueOrganizationsPageList',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
// 驳回党团申请
export const LeagueOrganizationsExamineRef = obj => requests({
    url: '/ass/leagueOrganizations/LeagueOrganizationsExamineRef',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
// 审核通过
export const LeagueOrganizationsExamineAbo = obj => requests({
    url: '/ass/leagueOrganizations/LeagueOrganizationsExamineAbo',
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})



// ---------------------------------------------------------------------------
// 活动审核模块
// 获取活动类型
export const partyActivityTypeList = (obj) => requests({
    url: "/ass/partyActivity/partyActivityTypeList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
// 获取活动模块列表数据
export const getreviewActivitiesList = (obj) => requests({
    url: "/ass/partyActivity/partyActivityPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 发布活动
export const partyActivityAdd = (obj) => requests({
    url: "/ass//partyActivity/partyActivityAdd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 驳回通过活动申请
export const trueDownapplyRes = (path, obj) => requests({
    url: path,
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
// 活动类型请求
export const activityTypeList = (obj) => requests({
    url: "/ass/partyActivity/partyActivityTypeList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
// 删除活动
export const partyActivityDel = (obj) => requests({
    url: "/ass/partyActivity/partyActivityDel",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 获取评论列表
export const commentPageList = (obj) => requests({
    url: "/ass/partyActivity/commentPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 拒绝评论
export const commentExamineRef = (obj) => requests({
    url: "/ass/partyActivity/commentExamineRef",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 活动置顶
export const partyActivityTopping = (obj) => requests({
    url: "/ass/partyActivity/partyActivityTopping",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})


// 通过评论
export const commentExamineAbo = (obj) => requests({
    url: "/ass/partyActivity/commentExamineAbo",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
// 置顶取消评论
export const commentTopping = (obj) => requests({
    url: "/ass/partyActivity/commentTopping",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})




// 机构zhaop
export const redruitingList = (type, obj, path) => requests({
    url: path,
    method: type,
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})


// 地区二维码++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export const regionQrCodePageList = (obj) => requests({
    url: "/ass/charTag/regionQrCodePageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 添加二维码
export const regionQrCodeAdd = (obj) => requests({
    url: "/ass/charTag/regionQrCodeAdd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 编辑二维码
export const regionQrCodeUpd = (obj) => requests({
    url: "/ass/charTag/regionQrCodeUpd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})
// 删除二维码
export const regionQrCodeDel = (obj) => requests({
    url: "/ass/charTag/regionQrCodeDel",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})

// 获取校区二维码
export const regionQrCodeMechanismPageList = (obj) => requests({
    url: "/ass/charTag/regionQrCodeMechanismPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("CHUCtoken")
    }
})


// 文件上传
// export const regionQrCodeMechanismPageList = (obj) => requests({
//     url: "/ass/charTag/regionQrCodeMechanismPageList",
//     method: "post",
//     data: obj,
//     headers: {
//         'Content-Type': 'application/json',
//         'token': window.sessionStorage.getItem("CHUCtoken")
//     }
// })