<template>
  <!-- 已通过活动 -->
  <div v-loading="loadingTab">
    <div v-if="commintIndex">
      <!-- 表格数据操作  -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input
            placeholder="活动名称查询"
            v-model="viewActiveFrom.remarks"
          ></el-input>
        </el-col>
        <el-col :span="3">
          <div class="block">
            <el-cascader
              v-model="adCodevalue"
              :options="areaData"
              :props="{ expandTrigger: 'hover' }"
              clearable
              placeholder="请选择地区"
              filterable
              @change="areaChange"
            ></el-cascader>
          </div>
        </el-col>

        <el-col :span="3">
          <el-select
            v-model="avtivityTypevalue"
            placeholder="活动类型"
            @change="avtivityType()"
            clearable
          >
            <el-option
              v-for="item in activityType"
              :label="item"
              :value="item"
              :key="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="getreviewActivities()"
          >
            <i class="el-icon-search"></i> </el-button
        ></el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column prop="name" label="机构名称"> </el-table-column>
        <el-table-column prop="title" label="活动名称"> </el-table-column>
        <el-table-column prop="type" label="活动类型"> </el-table-column>
        <el-table-column
          prop="creationTime"
          label="上传时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="活动照片" width="230">
          <template slot-scope="scope">
            <el-image
              style="width: 90px; height: 60px"
              :src="scope.row.picUrl"
              fit="cover"
              :previewSrcList="[scope.row.picUrl]"
            >
            </el-image>
        </template>
          <!-- <template slot-scope="scope">
            <el-popover placement="top-start" title="" trigger="hover">
              <img
                :src="scope.row.picUrl"
                alt=""
                style="width: 300px; height: 300px"
              />
              <img
                slot="reference"
                :src="scope.row.picUrl"
                class="active_imgs"
              />
            </el-popover>
          </template> -->
        </el-table-column>
        <el-table-column label="查看内容" width="230">
          <template slot-scope="scope">
            <el-button
              style="background-color: #5e6cf4; color: #fff"
              size="small"
              @click="okDialogVisibleClik(scope.row.content)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="活动积分" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="230" fixed="right">
          <template v-slot="scope">
            <el-button
              type="warning"
              plain
              icon="el-icon-check"
              size="small"
              @click="commintAply(scope.row)"
              >评论审核</el-button
            >
            <el-button
              :type="scope.row.topping == '0' ? 'success' : 'danger'"
              plain
              icon="sel-icon-top"
              size="small"
              @click="ActivityTop(scope.row)"
              >{{ scope.row.topping == "0" ? "活动" : "取消" }}置顶</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="viewActiveFrom.pageNum"
        :page-size="viewActiveFrom.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 评论页 -->
    <div v-if="!commintIndex">
      <h3 style="color: #999">
        <el-button
          type="primary"
          icon="el-icon-d-arrow-left"
          circle
          @click="BankClick"
          style="margin-right: 50px; background-color: #5e6cf4; color: #fff"
        ></el-button>
        机构名称 ：{{ activityarticle.name }}
        <label style="margin-left: 25px"
          >活动名称：{{ activityarticle.title }}</label
        >
      </h3>
      <el-tabs v-model="status" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in tabsList"
          :key="item.id"
          :label="item.value"
          :name="item.id"
        ></el-tab-pane>
      </el-tabs>
      <!-- 表格 -->
      <el-table
        :data="commentTableList"
        style="width: 100%"
        border
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column prop="nickName" label="用户名"> </el-table-column>
        <el-table-column label="用户头像">
          <template slot-scope="scope">
            <el-popover placement="top-start" title="" trigger="hover">
              <img
                :src="scope.row.avatarUrl"
                alt=""
                style="width: 300px; height: 300px"
              />
              <img
                slot="reference"
                :src="scope.row.avatarUrl"
                alt=""
                class="active_imgs"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="评论内容"> </el-table-column>
        <el-table-column prop="creationTime" label="评论时间">
        </el-table-column>
        <el-table-column prop="status" label="评论状态" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="是否置顶"
          show-overflow-tooltip
          v-if="status == '1'"
        >
          <template v-slot="scope">
            <div>
              {{ scope.row.topping == 0 ? "未置顶" : "已置顶" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-row v-if="status !== '1'">
              <el-col :span="14">
                <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定拒绝通过这活动条评论吗？"
                  @confirm="trueDownapply(scope.row)"
                >
                  <el-button
                    slot="reference"
                    type="danger"
                    plain
                    icon="el-icon-close"
                    size="small"
                    >拒绝</el-button
                  >
                </el-popconfirm>
              </el-col>
              <el-col :span="8">
                <el-popconfirm
                  confirm-button-text="通过"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定通过这条评论吗？"
                  @confirm="Okapply(scope.row)"
                >
                  <el-button
                    slot="reference"
                    type="success"
                    plain
                    icon="el-icon-check"
                    size="small"
                    >通过</el-button
                  >
                </el-popconfirm>
              </el-col>
            </el-row>
            <el-popconfirm
              confirm-button-text="通过"
              cancel-button-text="取消"
              icon-color="red"
              :title="
                '确定' + scope.row.topping == 0
                  ? '置顶评论'
                  : '取消置顶' + '这条评论吗？'
              "
              @confirm="OKtopping(scope.row)"
              v-else
            >
              <el-button
                slot="reference"
                type="warning"
                plain
                icon="el-icon-check"
                size="small"
                >{{
                  scope.row.topping == 0 ? "置顶评论" : "取消置顶"
                }}</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="commentPageListFrom.pageNum"
        :page-size="commentPageListFrom.pageSize"
        layout="prev, pager, next, jumper"
        :total="commentTotal"
      >
      </el-pagination>
    </div>
    <!-- <router-view></router-view> -->

    <!-- 富文本内容 -->
    <el-drawer
      title="查看活动内容"
      :visible.sync="okDialogVisible"
      direction="ltr"
      custom-class="demo-drawer"
      ref="drawer"
      size="25%"
    >
      <div class="demo-drawer_max">
        <div
          v-html="
            content.replace(/<img/g, '<img style=max-width:100%;height:auto; ')
          "
          style="height: 200px; width: 300px"
        ></div>
      </div>
      <!-- <div class="demo-drawe_buttpm">
          <el-button type="primary" @click="okDialogVisible = false"
            >返 回</el-button
          >
        </div> -->
    </el-drawer>
  </div>
</template>

<script>
import {
  getreviewActivitiesList,
  activityTypeList,
  commentPageList,
  commentTopping,
  commentExamineAbo,
  commentExamineRef,
  partyActivityTopping,
} from "@/api/index";

// 导入广东省地区编号文件
import { area } from "@/util/area-data";
export default {
  data() {
    return {
      tableData: [],
      total: 0, // 表格总数据
      search: "", //搜素内容绑定
      activeName: "first",
      loadingTab: false, //表格加载属性
      centerDialogVisible: false, //驳回修改对话框
      inputbohui: "", // 驳回内容
      serachValue: "", //搜索框内容绑定
      // 获取数据表单
      viewActiveFrom: {
        pageNum: 1,
        pageSize: 5,
        associationId: 1,
        type: "1",
        adCode: "",
        type: "",
        remarks: "",
        status: "1",
      },
      //活动类型
      activityType: [],
      // 活动类型下拉列表选择的值
      avtivityTypevalue: "",
      // 地区下拉列表
      adCodevalue: "",
      areaData: [],

      // 评论列表页
      commintIndex: true, //状态
      activityarticle: {}, //文章
      tabsList: [
        { id: "0", value: "待审核" },
        { id: "1", value: "已通过" },
      ],
      status: "0",
      // 评论列表获取表单
      commentPageListFrom: {
        partyActivityId: "",
        pageNum: 1,
        pageSize: 10,
        status: "0",
      },
      commentTableList: [],
      commentTotal: 0,
      // 富文本框
      okDialogVisible: false,
      content: "",
    };
  },

  methods: {
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.viewActiveFrom.pageSize = newsize;
      // 重新获取数据
      this.getreviewActivities();
    },

    handleCurrentChange(newpage) {
      this.viewActiveFrom.pageNum = newpage;
      // 重新获取数据
      this.getreviewActivities();
    },
    // 监听pageSize事件
    handleSizeChange1(newsize) {
      this.commentPageListFrom.pageSize = newsize;
      // 重新获取评论数据
      this.commentTable();
    },

    handleCurrentChange1(newpage) {
      this.commentPageListFrom.pageNum = newpage;
      // 重新获取评论数据
      this.commentTable();
    },

    // 获取数据方法
    async getreviewActivities() {
      this.loadingTab = true;
      console.log(this.viewActiveFrom);
      const dataLsit = await getreviewActivitiesList(this.viewActiveFrom);
      this.tableData = dataLsit.data.list;
      console.log(this.tableData);
      this.total = parseInt(dataLsit.data.total); //获取一共多少条数据
      this.loadingTab = false;
    },

    // 获取活动列表
    async activityTypeList() {
      const data = await activityTypeList({ associationId: 1 });
      console.log(data);
      this.activityType = data.data;
    },

    // 活动类型
    avtivityType() {
      this.viewActiveFrom.type = this.avtivityTypevalue;
      this.getreviewActivities();
    },

    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.viewActiveFrom.adCode = this.adCodevalue[0];
      } else {
        this.viewActiveFrom.adCode = this.adCodevalue[1];
      }
    },

    // 跳转评价审核页面
    commintAply(item) {
      this.activityarticle = item;
      console.log(item);
      this.commintIndex = false;
      this.$parent.ElTabs = false;
      this.$emit("EditElTabs", false);

      // 调用获取评论列表
      this.commentTable(item.partyActivityId);
      // this.$router.replace("/OfComments")
    },

    // 获取评论列表
    async commentTable(id) {
      // 获取评论数据】
      // this.commentPageListFrom.partyActivityId = 50;
      // console.log(id);
      this.commentPageListFrom.partyActivityId = id;
      const res = await commentPageList(this.commentPageListFrom);
      this.commentTableList = res.data.list ? res.data.list : [];
      console.log(this.commentTableList);
      this.commentTotal = parseInt(res.data.total);
    },

    // 评论状态选择
    handleClick(tab, event) {
      console.log(tab.index);
      this.commentPageListFrom.status = tab.index;
      this.commentPageListFrom.pageNum = 1;
      this.commentPageListFrom.pageSize = 7;
      this.commentTable(this.activityarticle.partyActivityId);
    },

    // 活动内容查看
    okDialogVisibleClik(content) {
      console.log(content);
      this.content = content;
      this.okDialogVisible = true;
    },

    // 拒绝通过评论
    async trueDownapply(item) {
      const res = await commentExamineRef({ commentId: item.commentId });
      console.log(res);
      if (res.status !== "success") return this.$message.error("拒绝失败");
      this.$message.success("拒绝成功");
      this.commentTable(this.activityarticle.partyActivityId);
    },

    // 通过
    async Okapply(item) {
      const res = await commentExamineAbo({ commentId: item.commentId });
      console.log(res);
      if (res.status !== "success") return this.$message.error("通过失败");
      this.$message.success("通过成功");
      this.commentTable(this.activityarticle.partyActivityId);
    },

    // 置顶评论
    async OKtopping(item) {
      let i = item.topping;
      const res = await commentTopping({ commentId: item.commentId });
      console.log(res);
      if (res.status !== "success")
        return this.$message.error(`${i == "0" ? "置顶" : "取消置顶"} 失败`);
      this.$message.success(`${i == "0" ? "置顶" : "取消置顶"} 成功`);
      this.commentTable(this.activityarticle.partyActivityId);
    },

    // 返回
    BankClick() {
      this.$emit("EditElTabs", true);
      this.commintIndex = true;
    },
    // 活动置顶
    async ActivityTop(from) {
      console.log(from);
      let i = from.topping;
      let res = await partyActivityTopping({
        partyActivityId: from.partyActivityId,
      });
      console.log(res);
      if (res.status !== "success")
        return this.$message.error(`${i == 0 ? "置顶" : "取消置顶"} 失败`);
      this.$message.success(`${i == 0 ? "置顶" : "取消置顶"} 成功`);
      this.getreviewActivities();
    },
  },
  created() {
    // 获取列表数据
    this.getreviewActivities();
    // 获取活动列表
    this.activityTypeList();
    const tokenstr = window.sessionStorage.getItem("CHUCtoken");
    // 如果不存在这个令牌的话就强制跳转到登录页面
    if (!tokenstr) return this.$router.push("/Login");
    this.areaData = area;
  },
};
</script>

<style scoped>
.active_imgs {
  width: 50px;
  height: 50px;
  margin-right: 8px;
}
</style>