<template>
  <!-- 机构认证 -->
  <el-card class="box-card">
    <div style="width: 95%; margin: 0 auto">
      <el-row :gutter="13">
        <el-col :span="22">
          <el-tabs v-model="status" @tab-click="handleClick">
            <el-tab-pane
              v-for="item in tabsList"
              :label="item.value"
              :name="item.id"
              :key="item.id"
            >
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="2">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            icon="el-icon-download"
            @click="derivedForm()"
            >导出所有</el-button
          >
        </el-col>
      </el-row>

      <!-- 老用户组件 -->
      <oldMemberVue :tabTypeIndex="status" v-if="status == '0'" />
      <newMemberVue :tabTypeIndex="status" v-if="status == '1'" />
      <paymentConfirmedVue :tabTypeIndex="status" v-if="status == '2'" />
      <resubmitVue :tabTypeIndex="status" v-if="status == '3'" />
      <certificationVue :tabTypeIndex="status" v-if="status == '4'" />
      <unrenewedDueVue :tabTypeIndex="status" v-if="status == '5'" />
    </div>
  </el-card>
</template>

<script>
//表格分类组件导入
import oldMemberVue from "./Table/oldMember.vue";
import newMemberVue from "./Table/newMember.vue";
import paymentConfirmedVue from "./Table/paymentConfirmed.vue";
import resubmitVue from "./Table/resubmit.vue";
import certificationVue from "./Table/certification.vue";
import unrenewedDueVue from "./Table/unrenewedDue.vue";

// 导入请求方法
import { derivedFormPost } from "@/api/index";

// 导入生产xls的方法
import { xlsTable } from "@/util/index";
export default {
  data() {
    return {
      // 选卡
      tabsList: [
        {
          id: "0",
          value: "老会员绑定",
        },
        {
          id: "1",
          value: "新会员申请",
        },
        {
          id: "2",
          value: "缴费待确认",
        },
        {
          id: "3",
          value: "待提交",
        },
        {
          id: "4",
          value: "机构认证",
        },
        { id: "5", value: "到期未续费" },
      ],
      status: "0",
      tableDowLink: "",
    };
  },
  methods: {
    // 选项卡事件
    handleClick(tab, event) {
      // 获取token
      const tokenstr = window.sessionStorage.getItem("CHUCtoken");
      // 如果不存在这个令牌的话就强制跳转到登录页面
      if (!tokenstr) return this.$router.push("/Login");
      this.status = tab.index;
    },

    //导出表格
    async derivedForm() {
      let obj = {
        type: this.tabTypeIndex,
        remarks: "",
      };
      const data = await derivedFormPost(obj);
      console.log(data);
      console.log(data.data);

      xlsTable(data.data, "老会员表格.xls");
    },
    
  },

  components: {
    oldMemberVue,
    newMemberVue,
    paymentConfirmedVue,
    resubmitVue,
    certificationVue,
    unrenewedDueVue,
  },

  created() {
    
  },
};
</script>




<style>
/* 多栏布局 */
.el-row {
  margin-bottom: 15px;
}
/* 悬浮样式 */
.el-tabs__item:hover {
  color: #5e6cf4 !important;
}
/* 选中样式 */
.el-tabs__item.is-active {
  color: #5e6cf4 !important;
}
.el-tabs__active-bar {
  background-color: #5e6cf4 !important;
}

.drawer {
  float: right;
  margin-right: 30px;
  margin-top: 15px;
}
/* 分页样式 */
.el-pagination {
  margin: 20px 0 0 0px;
}
</style>