<template>
  <!-- 缴费待确认组件 -->
  <div v-loading="loadingTab">
    <div>
      <!-- 表格数据操作  -->
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input
            placeholder="地区或品牌查询"
            v-model="getTableListFrom.remarks"
          >
          </el-input>
        </el-col>
        <el-col :span="3">
          <div class="block">
            <el-cascader
              v-model="adCodevalue"
              :options="areaData"
              :props="{ expandTrigger: 'hover' }"
              clearable
              placeholder="请选择地区"
              filterable
              @change="areaChange"
            ></el-cascader>
          </div>
        </el-col>
        <el-col :span="3">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="getTableList()"
          >
            <i class="el-icon-search"></i> </el-button
        ></el-col>
        <el-col :span="2">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            icon="el-icon-download"
            @click="derivedForm"
            >导出</el-button
          >
        </el-col>
      </el-row>

      <!-- <TbaPag /> -->
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column type="index" label="#" width="40"> </el-table-column>
        <el-table-column prop="brandName" label="机构名称"> </el-table-column>
        <el-table-column prop="chargeName" label="负责人"> </el-table-column>
        <el-table-column prop="chargePhone" label="联系电话"> </el-table-column>
        <el-table-column
          prop="brandAddress"
          label="通讯地址"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="缴费凭证">
          <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.payVoucher"
            fit="cover" :previewSrcList="[scope.row.payVoucher]">
          </el-image>
        </template>
        </el-table-column>
        <el-table-column prop="price" label="缴费金额" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="plaqueNumber"
          label="牌匾数量"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="creationTime"
          label="申请时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="reason" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="480">
          <template v-slot="scope">
            <el-row :gutter="0">
              <el-col :span="5">
                <el-button
                  icon="el-icon-close"
                  type="danger"
                  plain
                  size="small"
                  @click="registerDialog(scope.row.registerId)"
                  >驳回修改</el-button
                >
              </el-col>
              <!-- 通过 -->
              <el-col :span="8">
                <el-button
                  type="success"
                  plain
                  icon="el-icon-check"
                  size="small"
                  @click="byapplicationClick(scope.row)"
                  v-if="scope.row.status == '5'"
                  >通过(申领牌匾号)</el-button
                >
                <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定通过吗？"
                  @confirm="byapplicationClickTow(scope.row)"
                >
                  <el-button
                    slot="reference"
                    type="success"
                    plain
                    icon= "el-icon-check"
                    size= "small"
                    v-if="scope.row.status == '4'"
                    >通过</el-button
                  >
                </el-popconfirm>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-notebook-1"
                  size="small"
                  @click="viewDataDrawer(scope.row)"
                  >查看</el-button
                >
              </el-col>
              <el-col :span="6">
                <el-button
                  type="warning"
                  plain
                  icon="el-icon-notebook-2"
                  size="small"
                  @click="paymentRecordsDialog(scope.row)"
                  >缴费记录</el-button
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="getTableListFrom.pageNum"
        :page-sizes="[1, 2, 5, 8]"
        :page-size="getTableListFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 驳回弹出框 -->
    <el-dialog
      title="老用户驳回"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="8"
          ><span style="font-size: 17px; font-weight: bold"
            >驳回原因：</span
          ></el-col
        >
        <el-col :span="12"
          ><el-input v-model="inputbohui" placeholder="请输入内容"></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="registerDialogClick">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看资料对对话框 -->
    <el-dialog
      title="认证通过信息"
      :visible.sync="viewDataDialogVisible"
      width="30%"
      center
    >
      <div>
        <p
          v-show="
            seleleTable == undefined ||
            seleleTable == null ||
            seleleTable.length <= 0
          "
          class="nodata"
        >
          无数据
        </p>
        <p
          class="viewData"
          v-for="(item,index) in seleleTable"
          :key="index"
        >
          <label>{{ item.fileName }}:</label>
          <label>
            <el-button @click="viewDataWord(item)" type="primary" size="small"
              >查看</el-button
            >
            <el-button type="primary" size="small"> <a :href="item.url" >下载</a></el-button>
          </label>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="viewDataDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewDataDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 通过(申领牌匾)弹出框 -->
    <el-dialog
      title="认证通过信息"
      :visible.sync="okDialogVisible"
      width="30%"
      center
    >
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="11"
          ><span style="font-size: 17px; font-weight: bold"
            >选择到期时间：</span
          ></el-col
        >
        <el-col :span="10">
          <el-date-picker
            v-model="applyOk.time"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
          >
          </el-date-picker
        ></el-col>
      </el-row>
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="11"
          ><span style="font-size: 17px; font-weight: bold"
            >输入牌匾号:</span
          ></el-col
        >
        <el-col :span="10">
          <el-input v-model="applyOk.okid" placeholder="请输入牌匾号"></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="okDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="receivePlaqueByapplicationClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 查看缴费记录对话框 -->
    <el-dialog
      title="查看缴费记录"
      :visible.sync="paymentRecordVisible"
      width="40%"
      center
    >
      <div>
        <el-table :data="seleleTable" stripe style="width: 100%" border>
          <el-table-column type="index" label="#" width="80"> </el-table-column>
          <el-table-column prop="plaqueNumber" label="牌匾数量">
          </el-table-column>
          <el-table-column prop="price" label="支付金额"> </el-table-column>
          <el-table-column label="支付凭证" width="180">
            <template v-slot="scope" >
              <el-image
                  style="width: 90px; height: 60px"
                  :src="scope.row.payVoucher"
                  fit="cover" :previewSrcList="[scope.row.payVoucher]">
                </el-image>
              <!-- <el-button
                type="primary"
                size="small"
                @click="viewDataWord(scope.row)"
                >查看</el-button
              > -->
            </template>
          </el-table-column>
          <el-table-column prop="account" label="银行账户"></el-table-column>
          <el-table-column prop="bankDeposit" label="开户行"></el-table-column>
          <el-table-column prop="socialCode" label="纳税号"></el-table-column>
          <el-table-column
            prop="companyName"
            label="公司名称"
          ></el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="paymentRecordVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 导入请求方法
import {
  getAccreditedTableLsit,
  registerExaminePaRef,
  viewDataserve,
  paymentRecords,
  receivePlaqueByapplication,
  derivedFormPost,
  registerExaminePaAbo
} from "@/api/index";

// 导入生产xls的方法
import { xlsTable, priview } from "@/util/index";

// 导入广东省地区编号文件
import { area } from "@/util/area-data";
export default {
  data() {
    return {
      // 表格数据
      tableData: [],
      search: "", //搜素内容绑定
      loadingTab: false, //表格加载属性
      centerDialogVisible: false, //驳回弹出框
      inputbohui: "", //绑定驳回文本框
      EditAccDrawer: false, //修改数据弹框
      viewDataDialogVisible: false, //查看数据弹框
      paymentRecordVisible: false, //查看缴费记录弹框
      okDialogVisible: false, //通过申请牌匾号
      // 获取表格数据参数
      getTableListFrom: {
        pageNum: 1,
        pageSize: 5,
        status: "",
        associationId: 1,
        adCode: "",
      },
      total: 0, // 表格总数据
      // 临时存储用户的id字段
      storeId: "",
      loadingdrawer: false, //提交修改的加载
      seleleTable: [], //查看数据存储的数组
      // 通过申请领牌匾号
      applyOk: {
        time: "",
        okid: "",
      },
      // 地区下拉列表
      adCodevalue: "",
      areaData: [],
    };
  },
  props: ["tabTypeIndex"],
  methods: {
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.getTableListFrom.pageSize = newsize;
      // 重新获取数据
      this.getTableList();
    },

    handleCurrentChange(newpage) {
      this.getTableListFrom.pageNum = newpage;
      // 重新获取数据
      this.getTableList();
    },

    // 获取表格数据方法
    async getTableList() {
      console.log(this.getTableListFrom);
      this.loadingTab = true;
      // 发送获取表格数据的请求
      const TabList = await getAccreditedTableLsit(this.getTableListFrom);
      console.log(TabList);
      this.tableData = TabList.data.list; //将数据保存
      this.total = parseInt(TabList.data.total); //获取一共多少条数据
      this.loadingTab = false;
      setTimeout(() => {
        this.loadingTab = false;
      }, 1000);
    },

    // 驳回修改窗口
    registerDialog(id) {
      this.centerDialogVisible = true;
      this.storeId = id;
    },

    // 确认驳回的按钮事件
    async registerDialogClick() {
      this.centerDialogVisible = false;
      let obj = {
        registerId: this.storeId,
        reason: this.inputbohui,
      };
      console.log();
      const data = await registerExaminePaRef(obj);
      console.log(data);
      if (data.status === "success") {
        this.$message.success("资料驳回成功！");
        this.getTableList();
      } else {
        this.$message.error("资料驳回失败！");
      }
    },

    // 查看资料弹框
    async viewDataDrawer(item) {
      let obj = {
        registerId: item.registerId,
      };
      const data = await viewDataserve(obj);
      this.seleleTable = data.data;
      this.viewDataDialogVisible = true;
    },

    // 通过弹出框事件
    byapplicationClick(item) {
      this.okDialogVisible = true;
      this.storeId = item.registerId;
    },

    // 确认通过弹出框事件
    async receivePlaqueByapplicationClick() {
      // console.log(this.applyOk);
      // console.log(this.storeId);
      let obj = {
        registerId: this.storeId,
        plaqueNo: this.applyOk.okid,
        time: this.applyOk.time,
      };
      console.log(obj);
      const data = await receivePlaqueByapplication(obj);
      console.log(data);
      if (data.status === "success") {
        this.$message.success("通过成功！！");
      } else {
        this.$message.error("通过失败！！");
      }
      this.centerDialogVisible = false;
      this.okDialogVisible = false;
      this.getTableList();
    },

    // 第一次通过确认
    async byapplicationClickTow(item){
      console.log(item);
      const data = await registerExaminePaAbo({registerId:item.registerId});
      if (data.status === "success") {
        this.$message.success("通过成功！！");
      } else {
        this.$message.error("通过失败！！");
      }
      this.getTableList();
    },

    //预览数据
    viewDataWord(item) {
      console.log(item);
      window.open(item.url);
      // priview(item);
    },

    // 查看缴费记录弹框
    async paymentRecordsDialog(item) {
      this.paymentRecordVisible = true;
      let obj = {
        registerId: item.registerId,
      };
      console.log(obj);
      const data = await paymentRecords(obj);
      this.seleleTable = data.data.list;
    },

    //导出表格
    async derivedForm() {
      let obj = {
        type: this.tabTypeIndex,
        remarks: "",
      };
      const data = await derivedFormPost(obj);
      window.location.href = data.data;
      // xlsTable(data.data, "缴费待确认.xls");
    },

    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.getTableListFrom.adCode = this.adCodevalue[0];
      } else {
        this.getTableListFrom.adCode = this.adCodevalue[1];
      }
    },
  },

  mounted() {
    this.getTableListFrom.status = this.tabTypeIndex;
    //调用获取表格数据方法
    this.getTableList();
    this.areaData = area;
  },
};
</script>

<style scoped>
.viewData {
  display: flex;
  justify-content: space-between;
}
.nodata {
  text-align: center;
  font-size: 20px;
}
</style>