// 广东省各市、区、的代码编号
const area = [{
    value: "440100",
    label: "广州市",
    children: [{
        value: "440103",
        label: "荔湾区"
    }, {
        value: "440104",
        label: "越秀区"
    }, {
        value: "440105",
        label: "海珠区"
    }, {
        value: "440106",
        label: "天河区"
    }, {
        value: "440111",
        label: "白云区"
    }, {
        value: "440112",
        label: "黄埔区"
    }, {
        value: "440113",
        label: "番禺区"
    }, {
        value: "440114",
        label: "花都区"
    }, {
        value: "440115",
        label: "南沙区"
    }, {
        value: "440116",
        label: "萝岗区"
    }, {
        value: "440183",
        label: "增城市"
    }, {
        value: "440184",
        label: "从化市"
    }, {
        value: "440188",
        label: "东山区"
    }, {
        value: "440189",
        label: "其它区"
    }]
}, {
    value: "440200",
    label: "韶关市",
    children: [{
        value: "440203",
        label: "武江区"
    }, {
        value: "440204",
        label: "浈江区"
    }, {
        value: "440205",
        label: "曲江区"
    }, {
        value: "440222",
        label: "始兴县"
    }, {
        value: "440224",
        label: "仁化县"
    }, {
        value: "440229",
        label: "翁源县"
    }, {
        value: "440232",
        label: "乳源瑶族自治县"
    }, {
        value: "440233",
        label: "新丰县"
    }, {
        value: "440281",
        label: "乐昌市"
    }, {
        value: "440282",
        label: "南雄市"
    }, {
        value: "440283",
        label: "其它区"
    }]
}, {
    value: "440300",
    label: "深圳市",
    children: [{
        value: "440303",
        label: "罗湖区"
    }, {
        value: "440304",
        label: "福田区"
    }, {
        value: "440305",
        label: "南山区"
    }, {
        value: "440306",
        label: "宝安区"
    }, {
        value: "440307",
        label: "龙岗区"
    }, {
        value: "440308",
        label: "盐田区"
    }, {
        value: "440309",
        label: "其它区"
    }]
}, {
    value: "440400",
    label: "珠海市",
    children: [{
        value: "440402",
        label: "香洲区"
    }, {
        value: "440403",
        label: "斗门区"
    }, {
        value: "440404",
        label: "金湾区"
    }, {
        value: "440486",
        label: "金唐区"
    }, {
        value: "440487",
        label: "南湾区"
    }, {
        value: "440488",
        label: "其它区"
    }]
}, {
    value: "440500",
    label: "汕头市",
    children: [{
        value: "440507",
        label: "龙湖区"
    }, {
        value: "440511",
        label: "金平区"
    }, {
        value: "440512",
        label: "濠江区"
    }, {
        value: "440513",
        label: "潮阳区"
    }, {
        value: "440514",
        label: "潮南区"
    }, {
        value: "440515",
        label: "澄海区"
    }, {
        value: "440523",
        label: "南澳县"
    }, {
        value: "440524",
        label: "其它区"
    }]
}, {
    value: "440600",
    label: "佛山市",
    children: [{
        value: "440604",
        label: "禅城区"
    }, {
        value: "440605",
        label: "南海区"
    }, {
        value: "440606",
        label: "顺德区"
    }, {
        value: "440607",
        label: "三水区"
    }, {
        value: "440608",
        label: "高明区"
    }, {
        value: "440609",
        label: "其它区"
    }]
}, {
    value: "440700",
    label: "江门市",
    children: [{
        value: "440703",
        label: "蓬江区"
    }, {
        value: "440704",
        label: "江海区"
    }, {
        value: "440705",
        label: "新会区"
    }, {
        value: "440781",
        label: "台山市"
    }, {
        value: "440783",
        label: "开平市"
    }, {
        value: "440784",
        label: "鹤山市"
    }, {
        value: "440785",
        label: "恩平市"
    }, {
        value: "440786",
        label: "其它区"
    }]
}, {
    value: "440800",
    label: "湛江市",
    children: [{
        value: "440802",
        label: "赤坎区"
    }, {
        value: "440803",
        label: "霞山区"
    }, {
        value: "440804",
        label: "坡头区"
    }, {
        value: "440811",
        label: "麻章区"
    }, {
        value: "440823",
        label: "遂溪县"
    }, {
        value: "440825",
        label: "徐闻县"
    }, {
        value: "440881",
        label: "廉江市"
    }, {
        value: "440882",
        label: "雷州市"
    }, {
        value: "440883",
        label: "吴川市"
    }, {
        value: "440884",
        label: "其它区"
    }]
}, {
    value: "440900",
    label: "茂名市",
    children: [{
        value: "440902",
        label: "茂南区"
    }, {
        value: "440903",
        label: "茂港区"
    }, {
        value: "440923",
        label: "电白县"
    }, {
        value: "440981",
        label: "高州市"
    }, {
        value: "440982",
        label: "化州市"
    }, {
        value: "440983",
        label: "信宜市"
    }, {
        value: "440984",
        label: "其它区"
    }]
}, {
    value: "441200",
    label: "肇庆市",
    children: [{
        value: "441202",
        label: "端州区"
    }, {
        value: "441203",
        label: "鼎湖区"
    }, {
        value: "441223",
        label: "广宁县"
    }, {
        value: "441224",
        label: "怀集县"
    }, {
        value: "441225",
        label: "封开县"
    }, {
        value: "441226",
        label: "德庆县"
    }, {
        value: "441283",
        label: "高要市"
    }, {
        value: "441284",
        label: "四会市"
    }, {
        value: "441285",
        label: "其它区"
    }]
}, {
    value: "441300",
    label: "惠州市",
    children: [{
        value: "441302",
        label: "惠城区"
    }, {
        value: "441303",
        label: "惠阳区"
    }, {
        value: "441322",
        label: "博罗县"
    }, {
        value: "441323",
        label: "惠东县"
    }, {
        value: "441324",
        label: "龙门县"
    }, {
        value: "441325",
        label: "其它区"
    }]
}, {
    value: "441400",
    label: "梅州市",
    children: [{
        value: "441402",
        label: "梅江区"
    }, {
        value: "441421",
        label: "梅县"
    }, {
        value: "441422",
        label: "大埔县"
    }, {
        value: "441423",
        label: "丰顺县"
    }, {
        value: "441424",
        label: "五华县"
    }, {
        value: "441426",
        label: "平远县"
    }, {
        value: "441427",
        label: "蕉岭县"
    }, {
        value: "441481",
        label: "兴宁市"
    }, {
        value: "441482",
        label: "其它区"
    }]
}, {
    value: "441500",
    label: "汕尾市",
    children: [{
        value: "441502",
        label: "城区"
    }, {
        value: "441521",
        label: "海丰县"
    }, {
        value: "441523",
        label: "陆河县"
    }, {
        value: "441581",
        label: "陆丰市"
    }, {
        value: "441582",
        label: "其它区"
    }]
}, {
    value: "441600",
    label: "河源市",
    children: [{
        value: "441602",
        label: "源城区"
    }, {
        value: "441621",
        label: "紫金县"
    }, {
        value: "441622",
        label: "龙川县"
    }, {
        value: "441623",
        label: "连平县"
    }, {
        value: "441624",
        label: "和平县"
    }, {
        value: "441625",
        label: "东源县"
    }, {
        value: "441626",
        label: "其它区"
    }]
}, {
    value: "441700",
    label: "阳江市",
    children: [{
        value: "441702",
        label: "江城区"
    }, {
        value: "441721",
        label: "阳西县"
    }, {
        value: "441723",
        label: "阳东县"
    }, {
        value: "441781",
        label: "阳春市"
    }, {
        value: "441782",
        label: "其它区"
    }]
}, {
    value: "441800",
    label: "清远市",
    children: [{
        value: "441802",
        label: "清城区"
    }, {
        value: "441821",
        label: "佛冈县"
    }, {
        value: "441823",
        label: "阳山县"
    }, {
        value: "441825",
        label: "连山壮族瑶族自治县"
    }, {
        value: "441826",
        label: "连南瑶族自治县"
    }, {
        value: "441827",
        label: "清新县"
    }, {
        value: "441881",
        label: "英德市"
    }, {
        value: "441882",
        label: "连州市"
    }, {
        value: "441883",
        label: "其它区"
    }]
}, {
    value: "441900",
    label: "东莞市"
}, {
    value: "442000",
    label: "中山市"
}, {
    value: "445100",
    label: "潮州市",
    children: [{
        value: "445102",
        label: "湘桥区"
    }, {
        value: "445121",
        label: "潮安县"
    }, {
        value: "445122",
        label: "饶平县"
    }, {
        value: "445185",
        label: "枫溪区"
    }, {
        value: "445186",
        label: "其它区"
    }]
}, {
    value: "445200",
    label: "揭阳市",
    children: [{
        value: "445202",
        label: "榕城区"
    }, {
        value: "445221",
        label: "揭东县"
    }, {
        value: "445222",
        label: "揭西县"
    }, {
        value: "445224",
        label: "惠来县"
    }, {
        value: "445281",
        label: "普宁市"
    }, {
        value: "445284",
        label: "东山区"
    }, {
        value: "445285",
        label: "其它区"
    }]
}, {
    value: "445300",
    label: "云浮市",
    children: [{
        value: "445302",
        label: "云城区"
    }, {
        value: "445321",
        label: "新兴县"
    }, {
        value: "445322",
        label: "郁南县"
    }, {
        value: "445323",
        label: "云安县"
    }, {
        value: "445381",
        label: "罗定市"
    }, {
        value: "445382",
        label: "其它区"
    }]
}]

export {area}


