<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="请输入关键字" v-model="from.remarks"> </el-input>
      </el-col>

      <el-col :span="1.5">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="getTable()"
        >
          <i class="el-icon-search"></i> </el-button
      ></el-col>
    </el-row>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column prop="mechanism" label="机构名称"> </el-table-column>
      <el-table-column prop="address" label="机构地址"> </el-table-column>
      <el-table-column prop="chargePhone" label="联系方式"> </el-table-column>
      <el-table-column prop="creationTime" label="创建时间"> </el-table-column>
      <el-table-column prop="charTag" label="标签"> </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="from.pageNum"
        :page-size="from.pageSize"
        layout="prev, pager, next, jumper"
        :total="45"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { regionQrCodeMechanismPageList } from "@/api/index";
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      from: {
        pageNum: 1,
        pageSize: 15,
        remarks: "",
      },
      total: "",
    };
  },
  created() {
    this.getTable();
  },
  methods: {
    handleSizeChange(newsize) {
      this.from.pageSize = newsize;
      // 重新获取数据
      this.getTable();
    },
    handleCurrentChange(newpage) {
      this.from.pageNum = newpage;
      // 重新获取数据
      this.getTable();
    },
    async getTable() {
      console.log(this.from);
      let data = await regionQrCodeMechanismPageList(this.from);
      this.tableData = data.data.list;
      this.total = parseInt(data.data.total);
      console.log(data);
    },
  },
};
</script>

<style>
</style>