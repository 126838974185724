<template>
  <!-- 新会员申请组件 -->
  <div v-loading="loadingTab">
    <div>
      <!-- 表格数据操作  -->
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input
            placeholder="地区或品牌查询"
            v-model="getTableListFrom.remarks"
          >
          </el-input>
        </el-col>
        <el-col :span="3">
          <div class="block">
            <el-cascader
              v-model="adCodevalue"
              :options="areaData"
              :props="{ expandTrigger: 'hover' }"
              clearable
              placeholder="请选择地区"
              filterable
              @change="areaChange"
            ></el-cascader>
          </div>
        </el-col>
        <el-col :span="3">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="getTableList()"
          >
            <i class="el-icon-search"></i> </el-button
        ></el-col>
        <el-col :span="2">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            icon="el-icon-download"
            @click="derivedForm"
            >导出</el-button
          >
        </el-col>
      </el-row>

      <!-- <TbaPag /> -->
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column type="index" label="#" width="40"> </el-table-column>
        <el-table-column prop="brandName" label="机构名称"> </el-table-column>
        <el-table-column prop="chargeName" label="负责人"> </el-table-column>
        <el-table-column prop="chargePhone" label="联系电话"> </el-table-column>
        <el-table-column
          prop="brandAddress"
          label="通讯地址"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column prop="plaqueNo" label="牌匾号" show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column
          prop="creationTime"
          label="申请时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="reason" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="480">
          <template v-slot="scope">
            <el-row :gutter="0">
              <el-col :span="6">
                <el-button
                  icon="el-icon-close"
                  type="danger"
                  plain
                  size="small"
                  @click="registerDialog(scope.row.registerId)"
                  >驳回修改</el-button
                >
              </el-col>
              <el-col :span="6">
                <el-popconfirm
                  confirm-button-text="通过"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定通过这条【转缴费】申请吗？"
                  @confirm="paymentByTrasferClick(scope.row)"
                >
                  <el-button
                    slot="reference"
                    type="success"
                    plain
                    icon="el-icon-check"
                    size="small"
                    >通过(转缴费)</el-button
                  >
                </el-popconfirm>
              </el-col>
              <el-col :span="6">
                <el-button
                  type="warning"
                  plain
                  icon="el-icon-edit"
                  size="small"
                  @click="registerEditAccDrawer(scope.row)"
                  >修改机构</el-button
                >
              </el-col>
              <el-col :span="2">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-notebook-1"
                  size="small"
                  @click="viewDataDrawer(scope.row)"
                  >查看</el-button
                >
              </el-col>
              <!-- 通过 -->
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="getTableListFrom.pageNum"
        :page-sizes="[1, 2, 5, 8]"
        :page-size="getTableListFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 驳回弹出框 -->
    <el-dialog
      title="新会员驳回"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="8"
          ><span style="font-size: 17px; font-weight: bold"
            >驳回原因：</span
          ></el-col
        >
        <el-col :span="12"
          ><el-input v-model="inputbohui" placeholder="请输入内容"></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="registerDialogClick">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改数据的抽屉 -->
    <el-drawer
      title="修改机构信息"
      :visible.sync="EditAccDrawer"
      direction="ltr"
      custom-class="demo-drawer"
      ref="drawer"
      size="25%"
    >
      <div class="demo-drawer__content">
        <hr style="background-color: #bbb" />
        <el-form :model="EditAccForm" style="padding-right: 20px">
          <el-form-item label="输入机构名称:" label-width="110px">
            <el-input
              v-model="EditAccForm.brandName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="输入负责人:" label-width="110px">
            <el-input
              v-model="EditAccForm.chargeName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="输入联系电话:" label-width="110px">
            <el-input
              v-model="EditAccForm.chargePhone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="输入机构地址:" label-width="110px">
            <el-input
              v-model="EditAccForm.brandAddress"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>

        <div class="demo-drawer__footer drawer">
          <el-button>返 回</el-button>
          <el-button type="primary" @click="registerEditYes">{{
            loadingdrawer ? "提交中 ..." : "确 定"
          }}</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 查看资料对对话框 -->
    <el-dialog
      title="认证通过信息"
      :visible.sync="viewDataDialogVisible"
      width="30%"
      center
    >
      <div>
        <p
          v-show="
            seleleTable == undefined ||
            seleleTable == null ||
            seleleTable.length <= 0
          "
          class="nodata"
        >
          无数据
        </p>
        <p
          class="viewData"
          v-for="item in seleleTable"
          :key="item.associationFileId"
        >
          <label>{{ item.fileName }}:</label>
          <label class="viewData_lable">
            <el-button @click="viewDataWord(item)" type="primary" size="small"
              >查看</el-button
            >
            <!-- <el-button @click="donwLode(item)" type="primary" size="small"
              >下载</el-button
            > -->
            <!-- <a
              :href="item.url"
              :download="item.url"
              target="_blank"
              style="margin-left: 10px"
              ><el-button type="primary" size="small">下载</el-button></a
            > -->
          </label>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="viewDataDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewDataDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 导入请求方法
import {
  getAccreditedTableLsit,
  newregistration,
  viewDataserve,
  registerEdit,
  paymentByTrasfer,
  derivedFormPost,
} from "@/api/index";
// 导入生产xls的方法
import { xlsTable, priview } from "@/util/index";
// 导入广东省地区编号文件
import { area } from "@/util/area-data";
import axios from "axios";

export default {
  data() {
    return {
      // 表格数据
      tableData: [],
      loadingTab: false, //表格加载属性
      centerDialogVisible: false, //驳回弹出框
      inputbohui: "", //绑定驳回文本框
      EditAccDrawer: false, //修改数据弹框
      viewDataDialogVisible: false, //查看数据弹框
      // 获取表格数据参数
      getTableListFrom: {
        pageNum: 1,
        pageSize: 5,
        status: "",
        associationId: 1,
        remarks: "",
        adCode: "",
      },
      total: 0, // 表格总数据
      // 修改数据表单
      EditAccForm: {
        registerId: 0,
        remarks: "",
        chargePhone: "", //联系电话
        brandName: "", //机构名称
        chargeName: "", //负责人
        brandAddress: "", //地址
      },
      // 临时存储用户的id字段
      storeId: "",
      loadingdrawer: false, //提交修改的加载
      seleleTable: [], //查看数据存储的数组
      // 地区下拉列表
      adCodevalue: "",
      areaData: [],
    };
  },
  props: ["tabTypeIndex"],
  methods: {
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.getTableListFrom.pageSize = newsize;
      // 重新获取数据
      this.getTableList();
    },

    handleCurrentChange(newpage) {
      this.getTableListFrom.pageNum = newpage;
      // 重新获取数据
      this.getTableList();
    },

    // 获取表格数据方法
    async getTableList() {
      this.loadingTab = true;
      // 发送获取表格数据的请求
      const TabList = await getAccreditedTableLsit(this.getTableListFrom);
      this.tableData = TabList.data.list; //将数据保存
      this.total = parseInt(TabList.data.total); //获取一共多少条数据
      this.loadingTab = false;
      setTimeout(() => {
        this.loadingTab = false;
      }, 1000);
    },

    // 驳回修改窗口
    registerDialog(id) {
      this.centerDialogVisible = true;
      this.storeId = id;
    },

    // 确认驳回的按钮事件
    async registerDialogClick() {
      this.centerDialogVisible = false;
      let obj = {
        registerId: this.storeId,
        reason: this.inputbohui,
      };
      console.log(obj);
      const data = await newregistration(obj);
      console.log(data);
      if (data.status === "success") {
        this.$message.success("资料驳回成功！");
        this.getTableList();
      } else {
        this.$message.error("资料驳回失败！");
      }
    },

    // 通过（转缴费）
    async paymentByTrasferClick(item) {
      const data = await paymentByTrasfer({ registerId: item.registerId });
      this.getTableList();
      this.$message.success("通过成功！！！");
    },

    //修改机构数
    registerEditAccDrawer(item) {
      this.EditAccDrawer = true;
      console.log(item);
      // 并集，将this.EditAccForm中有得数据 item里的赋值进去 【ES6语法】
      Object.keys(this.EditAccForm).forEach((key) => {
        this.EditAccForm[key] = item[key];
      });
    },

    //确认修改按钮事件
    async registerEditYes() {
      this.loadingdrawer = true;
      const data = await registerEdit(this.EditAccForm);
      this.EditAccDrawer = false;
      if (data.status == "success") {
        this.$message.success("修改机构成功！");

        this.getTableList();
      } else {
        this.$message.error("修改机构失败!!");
      }
      this.loadingdrawer = false;
    },

    // 查看资料弹框
    async viewDataDrawer(item) {
      let obj = {
        registerId: item.registerId,
      };
      const data = await viewDataserve(obj);
      this.seleleTable = data.data;
      this.viewDataDialogVisible = true;
    },

    //预览数据
    viewDataWord(item) {
      window.open(item.url)
      // console.log(item.url.substring(item.url.lastIndexOf(".") + 1) === "txt");
      // if (item.url.substring(item.url.lastIndexOf(".") + 1) === "txt") {
      //   const element = document.createElement("a");
      //   element.setAttribute(
      //     "href",
      //     "data:text/plain;charset=utf-8," + encodeURIComponent(item.url)
      //   );
      //   element.setAttribute("download", "myCode");
      //   element.style.display = "none";
      //   element.click();
      // }else{
        // priview(item);
      // }
    },
    // 下载
    donwLode(item){
      if (item.url.substring(item.url.lastIndexOf(".") + 1) === "txt") {
        const element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(item.url)
        );
        element.setAttribute("download", "myCode");
        element.style.display = "none";
        element.click();
      }else{
        window.open(item.url)
      }
    },

    //导出表格
    async derivedForm() {
      let obj = {
        type: this.tabTypeIndex,
        remarks: "",
      };
      const data = await derivedFormPost(obj);
      window.location.href = data.data;
      // xlsTable(data.data, "新会员申请.xls");
    },

    handelDown(url) {
      // console.log(url);
      // window.open(url);
      // var link = document.createElement("a");
      // link.href = url;
      // link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
      // link.click();
      // window.location.href = url;
      // let a = document.createElement("a");
      // a.href =url;
      // a.click();
    },

    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.getTableListFrom.adCode = this.adCodevalue[0];
      } else {
        this.getTableListFrom.adCode = this.adCodevalue[1];
      }
    },
  },

  mounted() {
    this.getTableListFrom.status = this.tabTypeIndex;
    //调用获取表格数据方法
    this.getTableList();
    this.areaData = area;
  },
};
</script>

<style scoped>
.nodata {
  text-align: center;
  font-size: 20px;
}
.viewData {
  display: flex;
  justify-content: space-between;
}
.viewData_lable {
  width: 50px;
  text-align: right;
  flex: 1;
}
</style>