<template>
  <el-card>
    <!-- 推广地区 -->
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="请输入关键字" v-model="from.remarks"> </el-input>
      </el-col>
      <el-col :span="3">
        <div class="block">
          <el-cascader
            v-model="adCodevalue"
            :options="areaData"
            :props="{ expandTrigger: 'hover' }"
            clearable
            placeholder="请选择地区"
            filterable
            @change="areaChange"
          ></el-cascader>
        </div>
      </el-col>
      <el-col :span="1.5">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="getTable()"
        >
          <i class="el-icon-search"></i> </el-button
      ></el-col>
    </el-row>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column prop="charTag" label="渠道来源"> </el-table-column>
      <el-table-column prop="mechanism" label="机构名称"> </el-table-column>
      <el-table-column prop="address" label="机构地址"> </el-table-column>
      <el-table-column prop="chargeName" label="负责人"> </el-table-column>
      <el-table-column prop="chargePhone" label="联系方式"> </el-table-column>
      <el-table-column prop="creationTime" label="创建时间"> </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="from.pageNum"
        :page-size="from.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import { regionQrCodeMechanismPageList } from "@/api/index";

// 导入广东省地区编号文件
import { area } from "@/util/area-data";
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      from: {
        pageNum: 1,
        pageSize: 10,
        remarks: "",
        adCode:'',
      },
      total: 0,
      // 地区下拉列表
      adCodevalue: "",
      areaData: [],
    };
  },
  created() {
    this.getTable();
    this.areaData = area;
  },
  methods: {
    handleSizeChange(newsize) {
      this.from.pageSize = newsize;
      // 重新获取数据
      this.getTable();
    },
    handleCurrentChange(newpage) {
      this.from.pageNum = newpage;
      // 重新获取数据
      this.getTable();
    },
    async getTable() {
      console.log(this.from);
      let data = await regionQrCodeMechanismPageList(this.from);
      this.tableData = data.data.list;
      this.total = parseInt(data.data.total);
      console.log(data);
    },

    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.from.adCode = this.adCodevalue[0];
      } else {
        this.from.adCode = this.adCodevalue[1];
      }
    },
  },
};
</script>

<style>
</style>