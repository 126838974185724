<template>
  <div>
    <!-- 所有课程福利 -->
    <div
      :gutter="20"
      style="margin-bottom: 15px; display: flex; flex-wrap: wrap"
    >
      <div
        :span="5"
        style="display: flex; align-items: center; margin-right: 15px"
      >
        <!-- <span style="white-space: nowrap;">模糊搜索：</span> -->
        <el-input placeholder="请输入内容" v-model="getFrom.remarks">
        </el-input>
      </div>

      <div :span="2">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="getData()"
        >
          <i class="el-icon-search"></i> 搜索
        </el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      @cell-dblclick="copyText"
    >
      <el-table-column type="index" label="#"></el-table-column>
      <el-table-column prop="title" label="课程名称"></el-table-column>
      <el-table-column prop="integral" label="消耗积分"></el-table-column>
      <el-table-column prop="memberTag" label="会员免费">
        <template slot-scope="scope">
          <label>{{ scope.row.memberTag == "1" ? "是" : "否" }}</label>
        </template>
      </el-table-column>
      <el-table-column label="封面">
        <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.tryUrl"
            fit="cover"
            :previewSrcList="[scope.row.tryUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="courseUrl"
        label="视频链接"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">
          <label v-if=" scope.row.status == '0'">未通过</label>
          <label v-if=" scope.row.status == '1'">已通过</label>
          <label v-if=" scope.row.status == '2'">待审核</label>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="驳回原因"> </el-table-column>
      <el-table-column prop="status" label="操作" width="180">
        <template slot-scope="scope">
          <el-row
            :gutter="4"
            style="display: flex; align-items: center; justify-content: center"
          >
            <el-col>
              <el-button
                type="warning"
                plain
                icon="el-icon-edit-outline"
                size="small"
                @click="opedialog(scope.row)"
                >编辑</el-button
              >
            </el-col>
            <el-col>
              <el-popconfirm
                confirm-button-text="通过"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除这条申请吗？"
                @confirm="deleapply(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  plain
                  icon="el-icon-close"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getFrom.pageNum"
      :page-sizes="[3, 5, 8, 10]"
      :page-size="getFrom.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <el-dialog title="编辑" :visible.sync="dialogVisible2" width="45%">
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        label-position="left"
        style="padding: 0 30px"
      >
        <el-form-item label="课程名">
          <el-input v-model="form.title" placeholder="请输入课程名"></el-input>
        </el-form-item>
        <el-form-item label="解锁积分">
          <el-input-number
            v-model="form.integral"
            :min="1"
            :max="1000"
            label="解锁积分"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="会员免费">
          <el-switch
            v-model="form.memberTag"
            active-color="#13ce66"
            inactive-color="#ccc"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="视频封面">
          <upload v-model="form.tryUrl" type="image"></upload>
        </el-form-item>
        <el-form-item label="课程视频">
          <upload v-model="form.courseUrl" type="video"></upload>
        </el-form-item>
        <div class="upload">
          <el-button type="primary" @click="edit()">修改</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  welfareCourse,
  welfareCourseDel,
  welfareCourseResubmit,
} from "@/api/courseAPI";
import upload from "@/components/Upload/index.vue";

export default {
  components: {
    upload,
  },
  data() {
    return {
      tableData: [],
      getFrom: {
        pageNum: 1,
        pageSize: 8,
        remarks: "",
      },
      total: 0,
      dialogVisible2: false,
      form: {
        title: "",
        courseUrl: "",
        tryUrl: "",
        memberTag: false,
        integral: "",
        welfareCourseId: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      console.log(123);
      let res = await welfareCourse(this.getFrom);
      this.tableData = res.data.list;
      this.total = parseInt(res.data.total);
      console.log(res);
    },
    opedialog(item) {
      this.form = {
        welfareCourseId: item.welfareCourseId,
        title: item.title,
        courseUrl: item.courseUrl,
        tryUrl: item.tryUrl,
        memberTag: item.memberTag == "1" ? true : false,
        integral: parseInt(item.integral),
      };
      this.dialogVisible2 = true;
    },
    async edit() {
      console.log(this.form);
      for (var key in this.form) {
        if (!this.form[key] && key != "memberTag") {
          return this.$message.error("请将信息补充完整");
        }
      }
      const loading = this.$loading({
        lock: true,
        text: "操作中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.form.memberTag = this.form.memberTag ? "1" : "0";
      let res = await welfareCourseResubmit(this.form);
      console.log(res);
      if (res.status !== "success") {
        this.$$message.error(res.msg);
        loading.close();
        return;
      }
      this.dialogVisible2 = false;
      this.getData()
      loading.close();
    },
    // 删除
    async deleapply(item) {
      const loading = this.$loading({
        lock: true,
        text: "操作中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await welfareCourseDel({
        welfareCourseId: item.welfareCourseId,
      });
      if (res.status !== "success") {
        this.$$message.error(res.msg);
        loading.close();
        return;
      }
      this.getData();

      loading.close();
    },
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.getFrom.pageSize = newsize;
      // 重新获取数据
      this.getData();
    },

    handleCurrentChange(newpage) {
      this.getFrom.pageNum = newpage;
      // 重新获取数据
      this.getData();
    },
    // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  },
};
</script>

<style>
</style>