<template>
  <div>
    <!-- 待审核 -->
    <div
      :gutter="20"
      style="margin-bottom: 15px; display: flex; flex-wrap: wrap"
    >
      <div
        :span="5"
        style="display: flex; align-items: center; margin-right: 15px"
      >
        <!-- <span style="white-space: nowrap;">模糊搜索：</span> -->
        <el-input placeholder="请输入内容" v-model="getFrom.remarks">
        </el-input>
      </div>

      <div :span="2">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="getData()"
        >
          <i class="el-icon-search"></i> 搜索
        </el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      @cell-dblclick="copyText"
    >
      <el-table-column type="index" label="#"></el-table-column>
      <el-table-column prop="title" label="课程名称"></el-table-column>
      <el-table-column prop="integral" label="消耗积分"></el-table-column>
      <el-table-column prop="memberTag" label="会员免费">
        <template slot-scope="scope">
          <label>{{ scope.row.memberTag == "1" ? "是" : "否" }}</label>
        </template>
      </el-table-column>
      <el-table-column label="封面">
        <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.tryUrl"
            fit="cover"
            :previewSrcList="[scope.row.tryUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="courseUrl" label="视频链接" show-overflow-tooltip></el-table-column>
      <el-table-column prop="status" label="操作" width="300">
        <template slot-scope="scope">
          <el-row :gutter="4">
            <el-col :span="8">
              <el-button
                icon="el-icon-close"
                type="warning"
                plain
                size="small"
                @click="registerDialog(scope.row)"
                >驳回申请</el-button
              >
            </el-col>
            <!-- 通过 -->
            <el-col :span="8">
              <el-popconfirm
                confirm-button-text="通过"
                cancel-button-text="取消"
                icon-color="red"
                title="确定通过这条申请吗？"
                @confirm="Okapply(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="success"
                  plain
                  icon="el-icon-check"
                  size="small"
                  >通过</el-button
                >
              </el-popconfirm>
            </el-col>
            <el-col :span="8">
              <el-popconfirm
                confirm-button-text="通过"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除这条申请吗？"
                @confirm="deleapply(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  plain
                  icon="el-icon-close"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="reason" label="驳回原因"> </el-table-column> -->
    </el-table>

    <!-- 分页 -->

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getFrom.pageNum"
      :page-sizes="[3, 5, 8, 10]"
      :page-size="getFrom.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 驳回弹出框 -->
    <el-dialog
      title="驳回原因"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-row
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-col :span="7"
          ><p style="font-size: 17px; font-weight: bold">驳回原因：</p></el-col
        >
        <el-col :span="12"
          ><el-input
            v-model="bohuiFrom.reason"
            placeholder="请输入内容"
          ></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="registerDialogClick()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  welfareCoursePageListExamine,
  welfareCourseDel,
  welfareCourseAbo,
  welfareCourseRef,
} from "@/api/courseAPI";
export default {
  data() {
    return {
      tableData: [],
      getFrom: {
        pageNum: 1,
        pageSize: 8,
        remarks: "",
      },
      total: 0,
      centerDialogVisible: false,
      bohuiFrom: {
        welfareCourseId: "",
        reason: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await welfareCoursePageListExamine(this.getFrom);
      this.tableData = res.data.list;
      this.total = parseInt(res.data.total);
      console.log(res);
    },
    // 打开驳回弹窗
    registerDialog(item) {
      this.centerDialogVisible = true;
      this.bohuiFrom.welfareCourseId = item.welfareCourseId;
    },
    // 驳回
    async registerDialogClick() {
      if (this.bohuiFrom == "") {
        this.$message.error("请输入驳回原因");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "操作中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let res = await welfareCourseRef(this.bohuiFrom);
      if (res.status !== "success") {
        this.$$message.error(res.msg);
        loading.close();
        return;
      }
      this.getData();
      this.bohuiFrom = {
        welfareCourseId: "",
        reason: "",
      };
      this.centerDialogVisible = false;

      loading.close();
    },
    // 通过
    async Okapply(item) {
      const loading = this.$loading({
        lock: true,
        text: "操作中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await welfareCourseAbo({
        welfareCourseId: item.welfareCourseId,
      });
      if (res.status !== "success") {
        this.$$message.error(res.msg);
        loading.close();
        return;
      }
      this.getData();

      loading.close();
    },
    // 删除
    async deleapply(item) {
      const loading = this.$loading({
        lock: true,
        text: "操作中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await welfareCourseDel({
        welfareCourseId: item.welfareCourseId,
      });
      if (res.status !== "success") {
        this.$$message.error(res.msg);
        loading.close();
        return;
      }
      this.getData();

      loading.close();
    },

    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.getFrom.pageSize = newsize;
      // 重新获取数据
      this.getData();
    },

    handleCurrentChange(newpage) {
      this.getFrom.pageNum = newpage;
      // 重新获取数据
      this.getData();
    },
    // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  },
};
</script>

<style>
</style>