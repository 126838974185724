<template>
  <el-card>
    <div style="width: 95%; margin: 0 auto">
      <el-row :gutter="13">
        <el-col :span="22">
          <!-- 顶部选项卡 -->
          <el-tabs v-model="status" v-if="ElTabs">
            <el-tab-pane
              v-for="item in tabsList"
              :key="item.id"
              :label="item.value"
              :name="item.id"
            ></el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="2">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="derivedPublishingActivities = true"
            >发布活动</el-button
          >
        </el-col>
      </el-row>

      <reviewActivitiesVue v-if="status === '0'" />
      <partyActivitiesVue @EditElTabs="EditElTabs" v-if="status === '1'" />
    </div>

    <!-- 发布弹窗 -->
    <el-drawer
      title="发布活动"
      :visible.sync="derivedPublishingActivities"
      direction="ltr"
      custom-class="demo-drawer"
      ref="drawer"
      size="33%"
    >
      <div class="max_fromX">
        <div class="max_item">
          <label>活动类型</label>
          <div class="max_item_from">
            <el-select
              v-model="dataFrom.type"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="max_item">
          <label>活动名称</label>
          <div class="max_item_from">
            <el-input
              v-model="dataFrom.title"
              placeholder="请输入内容"
              style="width: 100%"
            ></el-input>
          </div>
        </div>
        <div class="max_item">
          <label>上传首图</label>
          <div class="max_item_from">
            <div class="imgSe">
              <div class="stareImgWrap seareBox">
                <el-image
                  v-show="dataFrom.picUrl"
                  class="seareBox"
                  :src="dataFrom.picUrl"
                  fit="cover"
                >
                </el-image>
                <el-upload
                  action=""
                  ref="aUpload"
                  list-type="picture-card"
                  :on-change="setUpLoadImg"
                  :auto-upload="false"
                  :file-list="fileList"
                  :class="[
                    'el-upLoadP',
                    dataFrom.picUrl ? 'opacity0' : 'opacity1',
                  ]"
                  :limit="1"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
        <div class="max_item">
          <label>备注</label>
          <div class="max_item_from">
            <el-input
              type="textarea"
              v-model="dataFrom.remarks"
              placeholder="请输入内容"
              style="width: 100%"
            ></el-input>
          </div>
        </div>
        <!-- 富文本内容 -->
        <div style="height: 700px">
          <div>
            活动内容：<label style="font-size: 11px; color: #999"
              >（上传照片尽量不要超过6MB）</label
            >
          </div>
          <br />
          <!-- <editor/> -->
          <el-upload
            v-show="false"
            class="upload-demo"
            :on-success="handleSuccess"
            :show-file-list="false"
            action=""
            list-type="picture-card"
            :auto-upload="false"
            :on-change="handleSuccess"
          >
            <el-button v-show="false" class="ivu-btn"></el-button>
          </el-upload>

          <quill-editor
            ref="QuillEditor"
            v-model="content"
            class="editor"
            :options="editorOption"
          />
        </div>
      </div>
      <div class="max_item" style="margin-buttom: 4px; height: 70px">
        <el-button class="button" @click="onEditor">发布</el-button>
      </div>
    </el-drawer>
  </el-card>
</template>

<script>
// 活动审核
import reviewActivitiesVue from "./Table/reviewActivities.vue";
import partyActivitiesVue from "./Table/partyActivities.vue";
import { partyActivityTypeList, partyActivityAdd } from "@/api/index";
import { upLoadFile } from "@/util/upLoadFile";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image", "video"],
  ["clean"], // remove formatting button
];

export default {
  data() {
    return {
      tabsList: [
        { id: "0", value: "活动审核" },
        { id: "1", value: "已通过" },
      ],
      status: "0",
      ElTabs: true,
      derivedPublishingActivities: false,
      // 活动类型
      options: [],
      dataFrom: {
        type: "",
        associationId: 1,
        brandId: "0",
        picUrl: "",
        title: "",
        content: "",
        remarks: "",
      },
      content: "",
      editorOption: {},

      // 图片
      fileList: [],

      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用iview图片上传
                  console.log(value);
                  document.querySelector(".ivu-btn").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
    };
  },
  components: {
    reviewActivitiesVue,
    partyActivitiesVue,
  },
  mounted() {
    // this.$on("EditElTabs",this.EditElTabs())
    this.getTypeList();
  },
  methods: {
    EditElTabs(f) {
      console.log(f);
      this.ElTabs = f;
    },

    async getTypeList() {
      let res = await partyActivityTypeList({ associationId: 1 });
      console.log(res);
      this.options = res.data;
    },

    async setUpLoadImg(file) {
      console.log(file);
      this.fileList = [];
      const fileUrlData = await upLoadFile(file); // 图片上传
      if (fileUrlData.data.status !== "success")
        return this.$message.error(msg);
      this.dataFrom.picUrl = fileUrlData.data.data.url;
      console.log(this.dataFrom.picUrl);
      // setTimeout(() => (this.formData.picUrl = fileUrlData.data.data.url), 500);
    },

    async onEditor() {
      // console.log("富文本内容=", this.$refs.QuillEditor.value);
      // console.log(this.dataFrom);
      this.dataFrom.content = this.$refs.QuillEditor.value;
      for (var key in this.dataFrom) {
        if (!this.dataFrom[key]) {
          return this.$message.error("请将信息补充完整");
        }
      }
      let res = await partyActivityAdd(this.dataFrom);
      console.log(res);
      if (res.status === "success") {
        this.$message.success("发布成功");
      } else {
        this.$message.error("发布失败");
      }
      this.derivedPublishingActivities = false;
      this.getTypeList()
    },

    // handleBeforeUpload(file) {
    //   // 检验文件类型
    //   const isIMAGE =
    //     file.type === "image/jpeg" ||
    //     file.type === "image/png" ||
    //     file.type === "image/bmp";
    //   if (!isIMAGE) {
    //     this.$message.error(`请上传图片类型文件！`);
    //     return false;
    //   }
    //   // 校检文件大小
    //   const isLt = file.size / 1024 / 1024 < this.fileSize;
    //   if (!isLt) {
    //     this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
    //     return false;
    //   }
    //   return true;
    // },

    async handleSuccess(res) {
      console.log(res);
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        const fileUrlData = await upLoadFile(res); // 图片上传
        if (fileUrlData.data.status !== "success")
          return this.$message.error(msg);
        // this.dataFrom.picUrl = fileUrlData.data.data.url;
        console.log(123);
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", fileUrlData.data.data.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        Message.error("图片插入失败");
      }
    },
  },
};
</script>

<style scoped>
.el-table >>> .warning-row {
  background: oldlace;
}

.el-table >>> .success-row {
  background: #f0f9eb;
}

.max_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.max_item .max_item_from {
  width: 70%;
  /* background-color: aqua; */
}
.max_item div .el-input,
.max_item div .el-option {
  width: 100%;
}

.editor {
  height: 450px;
}
.imgSe {
  margin-right: 57%;
}

/deep/ .el-upload {
  width: 100%;
  height: 100%;
  line-height: 82px;
}
/deep/ .el-upload .el-upload-dragger {
  width: 100%;
  height: 100%;
}

.seareBox {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  /* background-color: aqua; */
}

.stareImgWrap {
  position: relative;
}

.el-upLoadP {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s;
}

.opacity0 {
  opacity: 0;
}

.opacity1 {
  opacity: 1;
}

.el-upLoadP:hover {
  opacity: 1;
}
.button {
  margin: auto;
  background-color: #5e6cf4;
  color: #fff;
  margin-top: -30px;
}

/* .ivu-upload {
  display: none;
} */
.max_fromX {
  width: 90%;
  margin: auto;
  /* background-color: aqua; */
  height: auto;
}
</style>