<template>
  <el-card>
    <div style="width: 100%; margin: 0 auto">
      <el-row :gutter="13">
        <el-col :span="22">
          <!-- 顶部选项卡 -->
          <el-tabs v-model="status">
            <el-tab-pane
              v-for="item in tabsList"
              :key="item.id"
              :label="item.value"
              :name="item.id"
            ></el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="2">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="dialogVisible = true"
            >发布课程</el-button
          >
        </el-col>
      </el-row>
    </div>
    <passThrough v-if="status == '0'"></passThrough>
    <statusCourse v-if="status == '1'"></statusCourse>
    <okCourse v-if="status == '2'"></okCourse>
    <noPass v-if="status == '3'"></noPass>

    <el-dialog title="添加" :visible.sync="dialogVisible" width="45%">
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        label-position="left"
        style="padding: 0 30px"
      >
        <el-form-item label="课程名">
          <el-input v-model="form.title" placeholder="请输入课程名"></el-input>
        </el-form-item>
        <el-form-item label="解锁积分">
          <el-input-number
            v-model="form.integral"
            :min="1"
            :max="1000"
            label="解锁积分"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="会员免费">
          <el-switch
            v-model="form.memberTag"
            active-color="#13ce66"
            inactive-color="#ccc"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="视频封面">
          <upload v-model="form.tryUrl" type="image"></upload>
        </el-form-item>
        <el-form-item label="课程视频">
          <upload v-model="form.courseUrl" type="video"></upload>
        </el-form-item>
        <div class="upload">
          <el-button type="primary" @click="add()">上传</el-button>
        </div>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import passThrough from "./Table/passThrough.vue";
import noPass from "./Table/noPass.vue";
import okCourse from "./Table/okCourse.vue";
import statusCourse from "./Table/statusCourse.vue";

import upload from "@/components/Upload/index.vue";

import { brandAdd } from "@/api/courseAPI";
export default {
  components: {
    statusCourse,
    passThrough,
    okCourse,
    noPass,
    upload,
  },
  data() {
    return {
      tabsList: [
        { id: "0", value: "课程列表" },
        { id: "1", value: "待审核" },
        { id: "2", value: "已通过" },
        { id: "3", value: "已驳回" },
      ],
      status: "0",
      dialogVisible: false,
      form: {
        title: "",
        courseUrl: "",
        tryUrl: "",
        memberTag: false,
        integral: "",
      },
    };
  },
  methods: {
    async add() {
      console.log(this.form);
      for (var key in this.form) {
        if (!this.form[key] && key != 'memberTag') {
          return this.$message.error("请将信息补充完整");
        }
      }
      const loading = this.$loading({
        lock: true,
        text: "添加中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.form.memberTag = this.form.memberTag ? "1" : "0";
      let res = await brandAdd(this.form);
      console.log(res);
      if (res.status !== "success") {
        this.$$message.error(res.msg);
        loading.close();
        return;
      }
      this.dialogVisible = false
      loading.close();
    },
  },
};
</script>

<style>
.upload {
  text-align: center;
  margin-top: 20rpx;
}
</style>