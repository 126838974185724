<template>
  <el-card>

    <areaCode v-if="status === '0'" />
    <stored v-if="status === '1'" />
    <!-- <el-drawer title="添加员工" :visible.sync="addDialog">
      <div class="From_item" style="margin-bottom: 10rpx">
        <div>姓名</div>
        <el-input v-model="From1.name" placeholder="请输入姓名"></el-input>
      </div>
      <div class="From_item">
        <div>标记</div>
        <el-input
          v-model="From1.charTag"
          placeholder="只能是数字和英文"
        ></el-input>
      </div>
      <div class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="OKAddy()">确 定</el-button>
      </div>
    </el-drawer> -->
  </el-card>
</template>

<script>
import areaCode from "./Table/areaCode.vue";
import stored from "./Table/stored.vue";

import { regionQrCodeAdd } from "@/api/index";
export default {
  data() {
    return {
      tabsList: [
        {
          id: "0",
          value: "地区二维码",
        },
        {
          id: "1",
          value: "二维码校区",
        },
      ],
      From1: {
        name: "",
        charTag: "",
      },
      status: "0",
      // addDialog: false,
    };
  },
  methods: {
    handleClick(tab, event) {
      // 获取token
      const tokenstr = window.sessionStorage.getItem("token");
      // 如果不存在这个令牌的话就强制跳转到登录页面
      if (!tokenstr) return this.$router.push("/Login");
      this.status = tab.index;
    },
    // // 添加
    // async OKAddy() {
    //   console.log(this.From);
    //   if (this.From1.name === "" || this.From1.name === null) {
    //     this.$message.error("请输入名称");
    //     return;
    //   }
    //   if (this.From1.charTag === "" || this.From1.name === null) {
    //     this.$message.error("请输入标记");
    //     return;
    //   }
    //   let reg = /^[A-Za-z0-9]+$/;
    // //   console.log(!reg.test(this.From.charTag));
    //   if (!reg.test(this.From.charTag)) {
    //     this.$message.error("标记只能是数组和英文");
    //     return;
    //   }
    //   let data = await regionQrCodeAdd(this.From1);
    //   console.log(data);
    //   if (data.status === "success") {
    //     this.$message.success("添加成功");
    //   } else {
    //     this.$message.error("添加失败");
    //   }
    //   this.addDialog = false;
    //   this.status = this.status == "0" ? "1" : "0";
    //   this.$nextTick(() => {
    //     // 重新渲染组件
    //     this.status = this.status == "0" ? "1" : "0";
    //   });
    // },
  },
  components: {
    areaCode,
    stored,
  },
};
</script>

<style scoped>
/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  /* background-color: aqua; */
}
.From_item div {
  width: 80px;
}
.From_item .el-input,
.From_item .el-select {
  width: 80%;
}
.dialog-footer {
  text-align: center;
}
.el-row{
    display: flex;
    justify-content: flex-end;
}
</style>