import { REQUEST_URL } from '@/api/project.config'
import axios from 'axios';

//图片上传  传入upLoad组件获取的file
function upLoadFile(file){
  let param = new FormData();
  param.append("file", file.raw);
  return axios.post(
    "https://api.jamboxsys.com/jambox/ass/" + "file/upLoad",
    param,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
}

export { upLoadFile } 