<template>
  <div v-loading="loadingTab">
    <el-row :gutter="13">
      <el-col :span="4">
        <el-input placeholder="请输入关键字" v-model="dataFrom.remarks">
        </el-input>
      </el-col>
      <el-col :span="3">
        <div class="block">
          <el-cascader
            v-model="adCodevalue"
            :options="areaData"
            :props="{ expandTrigger: 'hover' }"
            clearable
            placeholder="请选择地区"
            filterable
            @change="areaChange"
          ></el-cascader>
        </div>
      </el-col>
      <el-col :span="15">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="getTabList()"
        >
          <i class="el-icon-search"></i> </el-button
      ></el-col>
      <el-col :span="2">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="ShowADDlog()"
          >添加</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column prop="name" label="地区"> </el-table-column>
      <el-table-column prop="charTag" label="标记"> </el-table-column>
      <el-table-column prop="creationTime" label="创建时间"> </el-table-column>
      <el-table-column label="二维码">
        <template slot-scope="scope">
            <el-image
              style="width: 90px; height: 60px"
              :src="scope.row.qrCode"
              fit="cover"
              :previewSrcList="[scope.row.qrCode]"
            >
            </el-image>
        </template>
        <!-- <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img
              :src="scope.row.qrCode"
              alt=""
              style="width: 300px; height: 300px"
            />
            <img
              slot="reference"
              :src="scope.row.qrCode"
              alt=""
              class="active_imgs"
            />
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column prop="endTime" label="到期时间"> </el-table-column>
      <el-table-column label="操作" width="200">
        <template v-slot="scope">
          <el-row :gutter="30">
            <el-col :span="12">
              <el-button
                icon="el-icon-edit-outline"
                type="primary"
                plain
                size="small"
                @click="EditItm(scope.row)"
                >修改</el-button
              >
            </el-col>
            <!-- 通过 -->
            <el-col :span="12">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除这条记录吗？"
                @confirm="Okdel(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  plain
                  icon="el-icon-delete"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataFrom.pageNum"
        :page-size="dataFrom.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 修改弹窗 -->
    <el-drawer title="编辑" :visible.sync="addDialog1">
      <div class="From_item" style="margin-bottom: 10rpx">
        <div>名称</div>
        <el-input v-model="From.name" placeholder="请输入内容"></el-input>
      </div>
      <!-- <div class="From_item">
        <div>标记</div>
        <el-input
          v-model="From.charTag"
          placeholder="只能是数字和英文"
        ></el-input>
      </div> -->
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="addDialog1 = false">取 消</el-button>
        <el-button type="primary" @click="OKedit()">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 添加弹窗   -->
    <el-drawer title="添加" :visible.sync="addDialog">
      <div class="From_item" style="margin-bottom: 10rpx">
        <div>姓名</div>
        <el-input v-model="From.name" placeholder="请输入姓名"></el-input>
      </div>
      <div class="From_item">
        <div>标记</div>
        <el-input
          v-model="From.charTag"
          placeholder="只能是数字和英文"
        ></el-input>
      </div>
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="OKAddy()">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  regionQrCodePageList,
  regionQrCodeUpd,
  regionQrCodeDel,
  regionQrCodeAdd,
} from "@/api/index";

// 导入广东省地区编号文件
import { area } from "@/util/area-data";

export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      loadingTab: false, //表格加载属性
      //   loadingTab:true,
      dataFrom: {
        pageNum: 1,
        pageSize: 6,
        remarks: "",
        adCode: "",
      },
      total: null,

      From: {
        name: "",
        charTag: "",
      },
      addDialog1: false,
      addDialog: false,
      // 地区下拉列表
      adCodevalue: "",
      areaData: [],
    };
  },
  created() {
    this.getTabList();
    this.areaData = area;
  },
  methods: {
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      this.getTabList();
    },
    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      this.getTabList();
    },

    // 获取数据
    async getTabList() {
      const TabList = await regionQrCodePageList(this.dataFrom);
      console.log(TabList);
      this.tableData = TabList.data.list;
      this.total = parseInt(TabList.data.total);
      //   console.log(TabList);
      this.loadingTab = false;
    },
    EditItm(item) {
      // let b = item
      this.From = JSON.parse(JSON.stringify(item));
      this.addDialog1 = true;
    },

    // 确定修改
    async OKedit() {
      console.log(this.From);
      let obj = {
        regionId: this.From.regionId,
        name: this.From.name,
      };
      let data = await regionQrCodeUpd(obj);
      console.log(data);
      if (data.status === "success") {
        this.$message.success("修改成功！！！");
      } else {
        this.$message.error("修改失败！！！");
      }
      this.addDialog1 = false;
      this.loadingTab = false;
      this.getTabList();
    },

    // 删除二维码
    async Okdel(item) {
      let data = await regionQrCodeDel({ regionId: item.regionId });
      if (data.status === "success") {
        this.$message.success("删除成功！！！");
      } else {
        this.$message.error("删除失败！！！");
      }
      this.loadingTab = false;
      this.getTabList();
    },
    // 显示添加弹框
    ShowADDlog() {
      this.addDialog = true;
      this.From.name = "";
      this.From.charTag = "";
    },

    // 添加
    async OKAddy() {
      console.log(this.From);
      if (this.From.name === "" || this.From.name === null) {
        this.$message.error("请输入名称");
        return;
      }
      if (this.From.charTag === "" || this.From.charTag === null) {
        this.$message.error("请输入标记");
        return;
      }

      let reg = /^[A-Za-z0-9]+$/;
      //   console.log(!reg.test(this.From.charTag));
      if (!reg.test(this.From.charTag)) {
        this.$message.error("标记只能是数组和英文");
        return;
      }
      let data = await regionQrCodeAdd(this.From);
      console.log(data);
      if (data.status === "success") {
        this.$message.success("添加成功");
      } else {
        this.$message.error("添加失败");
      }
      this.addDialog = false;
      this.From.name = "";
      this.From.charTag = "";

      this.getTabList();
      //   this.status = this.status == "0" ? "1" : "0";
      //   this.$nextTick(() => {
      //     // 重新渲染组件
      //     this.status = this.status == "0" ? "1" : "0";
      //   });
    },

    // 地区选择
    areaChange() {
      if (this.adCodevalue[1] == "" || this.adCodevalue[1] == null) {
        this.dataFrom.adCode = this.adCodevalue[0];
      } else {
        this.dataFrom.adCode = this.adCodevalue[1];
      }
    },
  },
};
</script>

<style scoped>
/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  /* background-color: aqua; */
}
.From_item div {
  width: 80px;
}
.From_item .el-input,
.From_item .el-select {
  width: 80%;
}
.dialog-footer {
  text-align: center;
}
/* .el-row {
  display: flex;
  justify-content: flex-end;
} */
</style>