<template>
  <div>
    <el-table
      :data="commentTableList"
      style="width: 100%"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column prop="nickName" label="用户名"> </el-table-column>
      <el-table-column label="用户头像">
        <template slot-scope="scope">
            <el-image
              style="width: 90px; height: 60px"
              :src="scope.row.avatarUrl"
              fit="cover"
              :previewSrcList="[scope.row.avatarUrl]"
            >
            </el-image>
        </template>
        <!-- <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img
              :src="scope.row.avatarUrl"
              alt=""
              style="width: 300px; height: 300px"
            />
            <img
              slot="reference"
              :src="scope.row.avatarUrl"
              alt=""
              class="active_imgs"
              fit="cover" :previewSrcList="[scope.row.avatarUrl]"
            />
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column prop="content" label="评论内容"> </el-table-column>
      <el-table-column prop="creationTime" label="评论时间"> </el-table-column>
      <el-table-column prop="status" label="评论状态" show-overflow-tooltip>
      </el-table-column>

      <el-table-column label="操作">
        <template v-slot="scope">
          <el-row>
            <el-col :span="14">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定拒绝通过这活动条评论吗？"
                @confirm="trueDownapply(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  plain
                  icon="el-icon-close"
                  size="small"
                  >拒绝</el-button
                >
              </el-popconfirm>
            </el-col>
            <el-col :span="8">
              <el-popconfirm
                confirm-button-text="通过"
                cancel-button-text="取消"
                icon-color="red"
                title="确定通过这条评论吗？"
                @confirm="Okapply(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="success"
                  plain
                  icon="el-icon-check"
                  size="small"
                  >通过</el-button
                >
              </el-popconfirm>
            </el-col>
          </el-row>

          <!-- <el-popconfirm
            confirm-button-text="通过"
            cancel-button-text="取消"
            icon-color="red"
            :title="
              '确定' + scope.row.topping == 0
                ? '置顶评论'
                : '取消置顶' + '这条评论吗？'
            "
            @confirm="OKtopping(scope.row)"
          >
            <el-button
              slot="reference"
              type="warning"
              plain
              icon="el-icon-check"
              size="small"
              >{{ scope.row.topping == 0 ? "置顶评论" : "取消置顶" }}</el-button
            >
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      :current-page="commentPageListFrom.pageNum"
      :page-size="commentPageListFrom.pageSize"
      layout="prev, pager, next, jumper"
      :total="commentTotal"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  commentPageList,
  commentExamineRef,
  commentExamineAbo,
} from "@/api/index";
export default {
  data() {
    return {
      commentTableList: [],
      commentTotal: 0,
      commentPageListFrom: {
        pageNum: 1,
        pageSize: 8,
        status: "0",
      },
    };
  },
  created() {
    this.getCommentPageList();
  },
  methods: {
    async getCommentPageList() {
      const res = await commentPageList(this.commentPageListFrom);
      console.log(res);
      this.commentTableList = res.data.list ? res.data.list : [];
      console.log(this.commentTableList);
      this.commentTotal = parseInt(res.data.total);
    },

    // 监听pageSize事件
    handleSizeChange1(newsize) {
      this.commentPageListFrom.pageSize = newsize;
      // 重新获取评论数据
      this.getCommentPageList();
    },

    handleCurrentChange1(newpage) {
      this.commentPageListFrom.pageNum = newpage;
      // 重新获取评论数据
      this.getCommentPageList();
    },

    // 拒绝通过评论
    async trueDownapply(item) {
      const res = await commentExamineRef({ commentId: item.commentId });
      console.log(res);
      if (res.status !== "success") return this.$message.error("拒绝失败");
      this.$message.success("拒绝成功");
      this.getCommentPageList();
    },

    // 通过
    async Okapply(item) {
      const res = await commentExamineAbo({ commentId: item.commentId });
      console.log(res);
      if (res.status !== "success") return this.$message.error("通过失败");
      this.$message.success("通过成功");
      this.getCommentPageList();
    },
  },
};
</script>

<style>
</style>