<template>
  <div class="login_container">
    <div class="login_Maxbox">
      <h1>CHUC·省青少年街舞协会</h1>
      <!-- 登录盒子 -->
      <el-card class="login_box">
        <el-form
          ref="loginformRef"
          :model="loginFrom"
          :rules="loginFromRules"
          size="small"
        >
          <el-form-item prop="username">
            <el-input
              type="text"
              placeholder="username"
              v-model="loginFrom.username"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              placeholder="password"
              v-model="loginFrom.password"
               @keyup.enter.native="submitForm()"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="jizu">
            <el-checkbox size="14" v-model="remPassword"
              ><span style="font-size: 10px">记住密码</span>
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button class="primary" type="primary" @click="submitForm()" 
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
      <p>果酱盒子</p>
    </div>
  </div>
</template>

<script>
import { LoginApi, getMenuList } from "@/api/index";
import axios from "axios";
export default {
  name: "LogiN",
  data() {
    return {
      // 登录表单
      loginFrom: {
        username: "",
        password: "",
      },
      // 表单验证
      loginFromRules: {
        username: [
          { required: true, message: "请输入登录名", trigger: "blur" },
          { min: 3, max: 10, message: "用户名长度在3-10之间", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 15, message: "密码长度在6-15之间", trigger: "blur" },
        ],
      },
      // 记住密码
      remPassword: false,
    };
  },
  created() {
    var vm = this;
    // 在页面加载时从cookie获取登录信息
    let userName = vm.getCookie("userName");
    let userPwd = vm.getCookie("userPwd");
    // 如果存在赋值给表单，并且将记住密码勾选
    if (userName) {
      vm.loginFrom.username = userName;
      vm.loginFrom.password = userPwd;
      vm.remPassword = false;
    }
    window.sessionStorage.clear();
  },
  methods: { 
    // 登录按钮
    submitForm() {
      // 判断表单验证是否通过
      this.$refs.loginformRef.validate(async (valid) => {
        // 表单验证是否通过
        if (!valid) return;

        // axios封装发送请求发送登录请求
        const data = await LoginApi(this.loginFrom);
        // 状态码判断
        if (data.status !== "success")
          return this.$message.error("登录失败！账号或密码错误！！");


        // 获取菜单数据
        const menuListDta = await  getMenuList({})
        console.log(menuListDta);

        if (menuListDta.status != "success")
          return this.$message.error("获取菜单数据失败");
        
        // 利用本地存储将菜单栏数据存储起来
        // console.log(menuListDta);
        let menuList = menuListDta.data.jurisdictionAdmin;
        console.log(menuList);
        window.sessionStorage.setItem("menuList", JSON.stringify(menuList));
        

        // 登录成功提示
        this.$message.success("登录成功！");
        // 登录成功后生成token令牌，保证所以API除了登录之外的都必须在登录之后再能访问；
        console.log(data);
        window.sessionStorage.setItem("CHUCtoken", data.data.token);


        // 调用记住密码方法
        this.setUserInfo();
        

        // 路由跳转
        this.$router.push("/Home");
      });
    },

    // 调用获取菜单数据的接口方法
    async getMenu() {
      // 根据权限获取菜单栏数据
      const meunList = await getMenuList();
      console.log(meunList);
    },

    // 记住密码方法
    setUserInfo() {
      var vm = this;
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (vm.remPassword) {
        vm.setCookie("userName", vm.loginFrom.username);
        vm.setCookie("userPwd", vm.loginFrom.password);
        vm.setCookie("checked", vm.remPassword);
      } else {
        vm.setCookie("userName", "");
        vm.setCookie("userPwd", "");
      }
    },

    // 获取cookie
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },

    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
    },
  },
};
</script>

<style scoped>
.login_container {
  background-color: #5e6cf4;
  width: 100%;
  height: 100vh;
}
/* 中间定位盒子 */
.login_Maxbox {
  width: 800px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* title文字 */
.login_Maxbox h1 {
  font-size: 50px;
  margin: 0;
  text-align: center;
  color: #fff;
}
/* 登录盒子 */
.login_Maxbox p {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 80px;
  color: #fff;
  font-size: 13px;
}
.login_box {
  width: 320px;
  height: 290px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-form {
  margin-top: 30px;
}
.el-input {
  font-size: 14px;
}
.el-checkbox {
  font-size: 14px;
}
/* 记住密码样式 */
.jizu {
  margin: 0px 0 20px 8px;
  color: rgb(77, 74, 74);
}
.primary {
  width: 100%;
  background-color: #5e6cf4;
  border: 1px solid #5e6cf4;
  opacity: 0.9;
  font-size: 13px;
}
</style>