// 当前这个模块：API进行统一的管理
import requests from "./request";

// 获取课程福利列表
export const welfareCourse = (data) => requests({
  url: "/ass/welfareCourse/welfareCoursePageList",
  method: "post",
  data,
})

// 获取已通过课程列表
export const welfareCoursePageListAbo = (data) => requests({
  url: "/ass/welfareCourse/welfareCoursePageListAbo",
  method: "post",
  data,
})

// 获取待审核课程列表
export const welfareCoursePageListExamine = (data) => requests({
  url: "/ass/welfareCourse/welfareCoursePageListExamine",
  method: "post",
  data,
})



// 获取已驳回
export const welfareCoursePageListRef = (data) => requests({
  url: "/ass/welfareCourse/welfareCoursePageListRef",
  method: "post",
  data,
})

// 通过
export const welfareCourseAbo = (data) => requests({
  url: "/ass/welfareCourse/welfareCourseAbo",
  method: "post",
  data,
})
// 驳回
export const welfareCourseRef = (data) => requests({
  url: "/ass/welfareCourse/welfareCourseRef",
  method: "post",
  data,
})
// 删除
export const welfareCourseDel = (data) => requests({
  url: "/ass/welfareCourse/welfareCourseDel",
  method: "post",
  data,
})

// 上传
export const brandAdd = (data) => requests({
  url: "/ass/welfareCourse/assAdd",
  method: "post",
  data,
})

// 编辑
export const welfareCourseResubmit = (data) => requests({
  url: "/ass/welfareCourse/welfareCourseResubmit",
  method: "post",
  data,
})