import Vue from "vue"
import VueRouter from "vue-router"

// 路由组件的导入
import Login from "@/components/Login" //登录
import Home from "@/components/Home" //主界面
//导入子页面
// 欢迎界面
import Welcome from "@/pages/Welcome/Welcome.vue"
import Accredited from "@/pages/Accredited_inst/index.vue"   //机构列表
import Polotocal from "@/pages/Polotocal_Organizations/index.vue"    //活动列表功能
import ReviewComments from "@/pages/Polotocal_Organizations/CommentList.vue"
// 机构招聘
import recruit from '@/pages/Recruiting_Agency/recruit.vue'  //招聘列表
import myresume from "@/pages/Recruiting_Agency/my_Resume.vue"

// 党团支部申请
import PartyLeague from "@/pages/PartyLeagueApply/index.vue"

// 地区二维码
import Areaqrcode from "@/pages/Areaqrcode/index.vue"
import stored from "@/pages/Areaqrcode/stored.vue"

// 课程福利
import courseWelfare from "@/pages/Course_welfare/list.vue"




Vue.use(VueRouter);
const router = new VueRouter({
    routes: [{ path: "/", redirect: "Login" },
    { path: '/Login', component: Login },
    {
        path: '/Home', component: Home,
        redirect: "/Welcome",
        children: [
            { path: "/Welcome", component: Welcome },
            { path: "/views/registration/index", component: Accredited },
            { path: "/views/activities/index", component: Polotocal},
            { path: "/views/activities/commentList", component: ReviewComments},
            { path: "/views/recruit/recruitList", component: recruit },
            { path: "/views/recruit/resumeList", component: myresume },
            { path: "/views/LeagueOrganizations/examine", component: PartyLeague },
            { path: "/views/qrCode/qrCodeList", component: Areaqrcode },
            { path: "/views/qrCode/storeList", component: stored },
            { path: "/views/coursewelfare/courseList", component: courseWelfare },
        ]
    }]
});


// 挂在路由守卫导航
router.beforeEach((to, from, next) => {
    // 如果是登录页面的话，就直接放行
    if (to.path === "/Login") return next();
    // 获取token
    const tokenstr = sessionStorage.getItem("CHUCtoken");
    // 如果不存在这个令牌的话就强制跳转到登录页面
    if (!tokenstr) return next("/Login");
    // 都没有问题的话就放行
    next();
})


export default router;