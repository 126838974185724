<template>
  <!-- 评论审核 -->
  <el-card>
    <div style="width: 95%; margin: 0 auto">
      <!-- 顶部选项卡 -->
      <el-tabs v-model="status" v-if="ElTabs" >
        <el-tab-pane
          v-for="item in tabsList"
          :key="item.id"
          :label="item.value"
          :name="item.id"
        ></el-tab-pane>
      </el-tabs>
      <reviewActivitiesVue v-if="status === '0'" />
      <partyActivitiesVue @EditElTabs="EditElTabs" v-if="status === '1'" />
    </div>
  </el-card>
</template>

<script>
// 活动审核
import reviewActivitiesVue from "./Table/ReviewOfComments.vue";
import partyActivitiesVue from "./Table/CommentsPassed.vue";

export default {
  data() {
    return {
      tabsList: [
        { id: "0", value: "评论审核" },
        { id: "1", value: "已通过" },
      ],
      status: "0",
      ElTabs:true
    };
  },
  components: {
    reviewActivitiesVue,
    partyActivitiesVue,
  },
  methods: {
    // 选项卡事件
    // handleClick(tab, event) {
    //   console.log(tab.index);
    //   this.status = tab.index;
    // },
    EditElTabs(f){
      console.log(f);
      this.ElTabs = f
    }

  },
  mounted(){
    // this.$on("EditElTabs",this.EditElTabs())
  }
};
</script>

<style scoped>
.el-table >>> .warning-row {
  background: oldlace;
}

.el-table >>> .success-row {
  background: #f0f9eb;
}
</style>